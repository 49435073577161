import axios from "axios";
import { getProducts } from "../services/ProductApi";

/**
 * load data from api
 * filter result
 * @returns 
 */
export function loadProducts() {
  return (dispatch) => {
    return getProducts().then((res) => {
      dispatch(getAllProducts(res.data.filter(el => el.vendable_web == "1" && el.actif == "1")));
    });
  };
}

/**
 * get all product action
 * @param {Object} products 
 * @returns 
 */
export function getAllProducts(products) {
  return {
    type: "GET_PRODUCTS",
    products: products,
  };
}

/**
 * add id to cart action
 * @param {integer} id 
 * @returns 
 */
export const addToCart= (payload)=>{
  return {
    type: "ADD_TO_CART",
    payload,
  };
};
/**
 * add arrayObject 
 * @param {ArrayObject} id 
 * @returns 
 */
export const addGratuits= (payload)=>{
  return {
    type: "ADD_TO_GRATUITS",
    payload,
  };
};
/**
 * delete item from card
 * @param {*} payload 
 * @returns 
 */
export function DeleteCart(payload){
  return{
      type:'DELETE_CART',
      payload
  }
}

/**
 * add id to favoris action
 * @param {integer} id 
 * @returns 
 */
export const addToFavoris= (payload)=>{
  return {
    type: "ADD_TO_FAVORIS",
    payload,
  };
};

/**
 * delete item from favoris
 * @param {*} payload 
 * @returns 
 */
export function DeleteFavoris(id){
  return{
      type:'DELETE_FAVORIS',
      id
  }
}

/**
 * delete all item from card
 * @param {*} payload 
 * @returns 
 */
export function DeleteAllCart(payload){
  return{
      type:'DELETE_ALL_CART',
      payload
  }
}


import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  mergeObjectByPromotionCode,
  mergeObjectByGratuitsCode,
  sumCartPrice,
  multiplicateNumber,
  getUniqueListBy,
} from "../../Utils/ObjectOperation";
import { connect } from "react-redux";
import * as actionCreators from "../../actions/index";
import ConfirmCommande from "../Buttons/ConfirmCommande";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { calculateRemise } from "../../Utils/CustumObjectOperation";
import { getAllPromotions, getPromotionProduit } from "../../services/PromotionApi";
import { getAllGratuites, getAllGratuitesConditions } from "../../services/GratuitesApi";
import { getProductByCode } from "../../services/ProductApi";
import GratuitsCard from "../Card/GratuitsCard";
import PopularProduct from "./PopularProduct";
import { isLogin } from "../../Utils/SessionUtils";

function Cart(props) {
  const [inputList, setinputList] = useState([]);
  const [remiseClient, setremiseClient] = useState(0);
  const [allGratuitProduct, setallGratuitProduct] = useState([]);

  useEffect(() => {
    //get remsie client
    if (localStorage.getItem("user")) {
      let client = JSON.parse(localStorage.getItem("user"));
      setremiseClient(client.remise_client);
    }
  }, []);

  useEffect(() => {
    //check for gratuits

    //check for promotion
    getAllPromotions().then((promotion) => {
      const promotions = promotion.data;

      getPromotionProduit().then((res) => {
        const promotionProduct = res.data;
        // merge two promotion list
        const mergePromotion = mergeObjectByPromotionCode(promotions, promotionProduct);
        // check for product if exist in condition promotions
        const items = props.products.addedItems;
        for (let i = 0; i < items.length; i++) {
          //if cartitems list codeproduit == mergedList.code
          let existItems = mergePromotion.filter(
            (el) =>
              el?.produit_code == items[i]?.code ||
              el?.gamme_code == items[i]?.gamme?.code ||
              el?.famille_code == items[i]?.famille?.code ||
              el?.sousfamille_code == items[i]?.sousfamille?.code
          );
          // if product exist in conditions promotions
          if (existItems.length != 0) {
            // add promotion key,value
            items[i]["isPromotion"] = "En promotion";
            items[i]["remise_promotion"] = existItems[0].remise;
            items[i]["initial_remise_promotion"] = existItems[0].remise;
            items[i]["qte_promotion"] = existItems[0].qte_min;
            items[i]["cartQuantity"] = 1;
            if (isLogin()) {
              items[i]["cartPrice"] = items[i]["prix_vente_nomashop_2"];
            } else {
              items[i]["cartPrice"] = items[i]["prix_vente_nomashop_1"];
            }

            items[i]["initial_remise_promotion"] = 0;
          } else {
            // initail key,value
            items[i]["cartQuantity"] = 1;
            if (isLogin()) {
              items[i]["cartPrice"] = items[i]["prix_vente_nomashop_2"];
            } else {
              items[i]["cartPrice"] = items[i]["prix_vente_nomashop_1"];
            }
          }
        }
        setinputList(items);
      });
      getAllGratuites().then((res) => {
        const gratuits = res.data;
        getAllGratuitesConditions().then((response) => {
          const gratuitsConditions = response.data;
          //merge two list of gratuits
          const mergeGratuits = mergeObjectByGratuitsCode(gratuits, gratuitsConditions);

          const items = props.products.addedItems;
          for (let i = 0; i < items.length; i++) {
            //if cart items list codeproduit == mergedList.code
            let existItems = mergeGratuits.filter((el) => el.produit_code == items[i].code);
            //if existItem not empty
            if (existItems.length != 0) {
              //add gratuites key,value to items
              items[i]["isGratuites"] = "gratuit";
              items[i]["qte_condition_gratuit"] = existItems[0].qte;
              items[i]["produit_gratuit"] = existItems[0].gratuit_code;
              items[i]["qte_gratuit"] = existItems[0].quantite_gratuit;
              items[i]["code_gratuit"] = existItems[0].code;
              items[i]["cumulable"] = existItems[0].cumulable;
              //initail key,value
              items[i]["cartQuantity"] = existItems[0].qte;
              if (isLogin()) {
                items[i]["cartPrice"] = multiplicateNumber(items[i]["prix_vente_nomashop_2"], items[i]["cartQuantity"]);
              } else {
                items[i]["cartPrice"] = multiplicateNumber(items[i]["prix_vente_nomashop_1"], items[i]["cartQuantity"]);
              }

              //add gratuits to product items
              if (items[i]["cartQuantity"] == items[i]["qte_condition_gratuit"]) {
                //get Product by id
                getProductByCode(items[i]["produit_gratuit"]).then((res) => {
                  let productItem = res.data;
                  // add gratuits key,value
                  productItem[0]["cartQuantity"] = items[i]["qte_gratuit"];
                  productItem[0]["code_gratuit"] = items[i]["code_gratuit"];
                  // add produict to gratuit list
                  setallGratuitProduct((allGratuitProduct) => [...allGratuitProduct, productItem]);
                });
              }
            } else {
              // initail key,value
              items[i]["cartQuantity"] = 1;
              if (isLogin()) {
                items[i]["cartPrice"] = items[i]["prix_vente_nomashop_2"];
              } else {
                items[i]["cartPrice"] = items[i]["prix_vente_nomashop_1"];
              }
            }
          }
          setinputList(items);
        });
      });
    });
  }, []);

  /**
   * onchange increment or increment in product list item
   * @param {*} e
   * @param {*} index
   */
  const handleInputChange = (e, index) => {
    //const items = productCart;
    const items = [...props.products.addedItems];
    //item.cartQuantity += 1;
    items[index]["cartQuantity"] = Number(e.target.value);

    // promotion condition
    if (items[index]["cartQuantity"] >= items[index]["qte_promotion"]) {
      items[index]["initial_remise_promotion"] = items[index]["remise_promotion"];
      let price_ttc = 0;

      if (isLogin()) {
        price_ttc = Number(items[index]["prix_vente_nomashop_2"]) * Number(items[index]["cartQuantity"]);
      } else {
        price_ttc = Number(items[index]["prix_vente_nomashop_1"]) * Number(items[index]["cartQuantity"]);
      }

      items[index]["cartPrice"] = calculateRemise(price_ttc, items[index]["remise_promotion"]);
    } else {
      items[index]["initial_remise_promotion"] = 0;

      if (isLogin()) {
        items[index]["cartPrice"] = (
          Number(items[index]["prix_vente_nomashop_2"]) * Number(items[index]["cartQuantity"])
        ).toFixed(3);
      } else {
        items[index]["cartPrice"] = (
          Number(items[index]["prix_vente_nomashop_1"]) * Number(items[index]["cartQuantity"])
        ).toFixed(3);
      }
    }

    //gratuit conditions
    if (items[index]["cartQuantity"] == items[index]["qte_condition_gratuit"]) {
      getProductByCode(items[index]["produit_gratuit"]).then((res) => {
        let productItem = res.data;
        productItem[0]["cartQuantity"] = items[index]["qte_gratuit"];
        productItem[0]["code_gratuit"] = items[index]["code_gratuit"];
        setallGratuitProduct((allGratuitProduct) => [...allGratuitProduct, productItem]);
      });
    }

    // check if the gratuits product is cumulable
    if (items[index]["cumulable"] == 1) {
      if (Number(items[index]["cartQuantity"]) % Number(items[index]["qte_condition_gratuit"]) === 0) {
        const result = Number(items[index]["cartQuantity"]) / Number(items[index]["qte_condition_gratuit"]);
        getProductByCode(items[index]["produit_gratuit"]).then((res) => {
          let productItem = res.data;
          productItem[0]["cartQuantity"] = items[index]["qte_gratuit"] * result;
          productItem[0]["code_gratuit"] = items[index]["code_gratuit"];
          setallGratuitProduct((allGratuitProduct) => [...allGratuitProduct, productItem]);
        });
      }
    }

    setinputList(items);
  };

  /**
   * icrease item cart
   * @param {*} item
   * @param {*} index
   */
  const icrementCount = (item, index) => {
    //const items = productCart;
    var items = [...props.products.addedItems];
    //item.cartQuantity += 1;
    items[index]["cartQuantity"] += 1;

    // promotion condition
    if (items[index]["cartQuantity"] >= items[index]["qte_promotion"]) {
      items[index]["initial_remise_promotion"] = items[index]["remise_promotion"];

      let price_ttc = 0;
      if (isLogin()) {
        price_ttc = Number(items[index]["prix_vente_nomashop_2"]) * Number(items[index]["cartQuantity"]);
      } else {
        price_ttc = Number(items[index]["prix_vente_nomashop_1"]) * Number(items[index]["cartQuantity"]);
      }
      items[index]["cartPrice"] = calculateRemise(price_ttc, items[index]["remise_promotion"]);
    } else {
      items[index]["initial_remise_promotion"] = 0;
      if (isLogin()) {
        items[index]["cartPrice"] = (
          Number(items[index]["prix_vente_nomashop_2"]) * Number(items[index]["cartQuantity"])
        ).toFixed(3);
      } else {
        items[index]["cartPrice"] = (
          Number(items[index]["prix_vente_nomashop_1"]) * Number(items[index]["cartQuantity"])
        ).toFixed(3);
      }
    }

    //gratuit conditions
    if (items[index]["cartQuantity"] == items[index]["qte_condition_gratuit"]) {
      getProductByCode(items[index]["produit_gratuit"]).then((res) => {
        let productItem = res.data;
        productItem[0]["cartQuantity"] = items[index]["qte_gratuit"];
        productItem[0]["code_gratuit"] = items[index]["code_gratuit"];
        setallGratuitProduct((allGratuitProduct) => [...allGratuitProduct, productItem]);
      });
    }
    // check if the gratuits product is cumulable
    if (items[index]["cumulable"] == 1) {
      if (Number(items[index]["cartQuantity"]) % Number(items[index]["qte_condition_gratuit"]) === 0) {
        const result = Number(items[index]["cartQuantity"]) / Number(items[index]["qte_condition_gratuit"]);
        getProductByCode(items[index]["produit_gratuit"]).then((res) => {
          let productItem = res.data;
          productItem[0]["cartQuantity"] = items[index]["qte_gratuit"] * result;
          productItem[0]["code_gratuit"] = items[index]["code_gratuit"];
          setallGratuitProduct((allGratuitProduct) => [...allGratuitProduct, productItem]);
        });
      }
    }

    setinputList(items);
  };
  /**
   * decrese item cart
   * @param {*} item
   * @param {*} index
   */
  const decrementCount = (item, index) => {
    //const items = productCart;
    const items = [...props.products.addedItems];
    //item.cartQuantity -= 1;
    items[index]["cartQuantity"] -= 1;
    if (items[index]["cartQuantity"] >= items[index]["qte_promotion"]) {
      items[index]["initial_remise_promotion"] = items[index]["remise_promotion"];
      let price_ttc = 0;

      if (isLogin()) {
        price_ttc = Number(items[index]["prix_vente_nomashop_2"]) * Number(items[index]["cartQuantity"]);
      } else {
        price_ttc = Number(items[index]["prix_vente_nomashop_1"]) * Number(items[index]["cartQuantity"]);
      }
      items[index]["cartPrice"] = calculateRemise(price_ttc, items[index]["remise_promotion"]);
    } else {
      items[index]["initial_remise_promotion"] = 0;

      if (isLogin()) {
        items[index]["cartPrice"] = (
          Number(items[index]["prix_vente_nomashop_2"]) * Number(items[index]["cartQuantity"])
        ).toFixed(3);
      } else {
        items[index]["cartPrice"] = (
          Number(items[index]["prix_vente_nomashop_1"]) * Number(items[index]["cartQuantity"])
        ).toFixed(3);
      }
    }

    if (items[index]["cartQuantity"] < items[index]["qte_condition_gratuit"]) {
      const elements = allGratuitProduct.filter((el) => {
        return el[0].code_gratuit != items[index]["code_gratuit"];
      });

      setallGratuitProduct(elements);
    }
    // check if the gratuits product is cumulabel
    if (items[index]["cumulable"] == 1) {
      // check if the rest of division is equal to 0
      if (Number(items[index]["cartQuantity"]) % Number(items[index]["qte_condition_gratuit"]) === 0) {
        // result of division
        const result = Number(items[index]["cartQuantity"]) / Number(items[index]["qte_condition_gratuit"]);

        getProductByCode(items[index]["produit_gratuit"]).then((res) => {
          let productItem = res.data;
          productItem[0]["cartQuantity"] = items[index]["qte_gratuit"] * result;
          productItem[0]["code_gratuit"] = items[index]["code_gratuit"];
          setallGratuitProduct((allGratuitProduct) => [...allGratuitProduct, productItem]);
        });
      }
    }

    setinputList(items);
  };

  /**
   * delete item from cart
   * @param {*} item
   * @param {*} index
   */
  const handleDelete = (item, index) => {
    const items = props.products.addedItems;

    const newList = items.filter((el) => {
      return el.code_gratuit != items[index].code_gratuit;
    });

    const listWithGratuits = items.filter((el) => {
      return el.code_gratuit == items[index].code_gratuit && items[index].code_gratuit != undefined;
    });

    const differenceList = items.filter((el) => {
      return el.code_gratuit == items[index].code_gratuit;
    });

    if (listWithGratuits.length === 0) {
      props.DeleteCart(items[index].id);
      setinputList(
        items.filter((item) => {
          return item.id != items[index].id;
        })
      );
    } else {
      //delete item from input list

      for (let i = 0; i < differenceList.length; i++) {
        //delete item from redux
        props.DeleteCart(differenceList[i].id);
      }
      setinputList(newList);
    }

    //delete item in gratuits list by id
    const elements = allGratuitProduct.filter((el) => {
      return el[0].code_gratuit != items[index]["code_gratuit"];
    });
    setallGratuitProduct(elements);
  };

  /**
   * delete all cart items
   */
  const handleDeleteAllCart = () => {
    // delete all items from inputList
    const items = props.products.addedItems;
    items.splice(0, items.length);
    setinputList(items);
    //delete gratuits arrayObject
    const GratuitItems = allGratuitProduct;
    GratuitItems.splice(0, GratuitItems.length);
    setallGratuitProduct(GratuitItems);
    //delete all items from redux
    props.DeleteAllCart();
  };

  return (
    <div>
      <section className="section-content padding-y bg">
        <div className="container">
          <ToastContainer></ToastContainer>
          <div className="card">
            <div className="row no-gutters">
              <aside className="col-md-9">
                {inputList.map((item, index) => (
                  <article key={index} className="card-body border-bottom">
                    <div className="row">
                      <div className="col-md-7">
                        <figure className="media">
                          <div className="img-wrap mr-3">
                            <img
                              src={item.image ? item.image : "/images/product/product.png"}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/images/product/product.png";
                              }}
                              className="border img-sm"
                            />
                          </div>
                          <figcaption className="media-body">
                            <Link to={"/detail/" + item.code} className="title h6">
                              {item.libelle}
                            </Link>
                            <small className="label-rating text-muted">code article : {item.code}</small>
                            <div className="price-wrap">{item.cartPrice} DT</div>
                            {/* {item.isGratuites && (
                              <div className="price-wrap">
                                {item.produit_gratuit} sera {item.isGratuites}
                                si vous ajoutez X {item.qte_condition_gratuit}
                                de ce produit
                              </div>
                            )} */}
                            {item.remise_promotion && (
                              <>
                                <p>{item.isPromotion}</p>
                                <small className="label-rating text-muted">{item.remise_promotion} % remise</small>
                                <small className="label-rating text-success">
                                  <i className="fa fa-clipboard-check"></i>
                                  {item.qte_promotion} pièces achetées
                                </small>
                              </>
                            )}
                          </figcaption>
                        </figure>
                      </div>
                      <div className="col-md-5 text-md-right text-right">
                        <div className="input-group input-spinner">
                          <div className="input-group-prepend">
                            <button
                              className="btn btn-light"
                              type="button"
                              id="button-plus"
                              onClick={() => icrementCount(item, index)}
                            >
                              +
                            </button>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            value={item.cartQuantity}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                          <div className="input-group-append">
                            <button
                              disabled={item.cartQuantity == "1"}
                              className={item.cartQuantity == "1" ? "btn btn-light disabled" : "btn btn-light"}
                              type="button"
                              id="button-minus"
                              onClick={() => decrementCount(item, index)}
                            >
                              −
                            </button>
                          </div>
                        </div>
                        <Link to={"/cart"} className="btn btn-light" onClick={() => handleDelete(item, index)}>
                          <i className="fa fa-trash" />
                        </Link>
                      </div>
                    </div>
                  </article>
                ))}

                {allGratuitProduct.length > 0 && (
                  <GratuitsCard gratuiProduct={getUniqueListBy(allGratuitProduct, "code_gratuit")}></GratuitsCard>
                )}
              </aside>
              <aside className="col-md-3 border-left">
                <div className="card-body">
                  <dl className="dlist-align">
                    <dt>Nombre des articles:</dt>
                    <dd className="text-right text-danger">{inputList.length}</dd>
                  </dl>
                  <dl className="dlist-align">
                    <dt>Prix total:</dt>
                    <dd className="text-right">{sumCartPrice(inputList)} DT</dd>
                  </dl>
                  <dl className="dlist-align">
                    {remiseClient == 0 ? (
                      <p></p>
                    ) : (
                      <>
                        <dt>Remise client :</dt>
                        <dd className="text-right">{remiseClient} %</dd>
                      </>
                    )}
                  </dl>

                  <dl className="dlist-align">
                    <dt>Total:</dt>
                    <dd className="text-right text-dark b">
                      <strong>
                        {calculateRemise(sumCartPrice(inputList), remiseClient)}
                        DT
                      </strong>
                    </dd>
                  </dl>
                  <hr />
                  <ConfirmCommande
                    commande={inputList}
                    gratuits={getUniqueListBy(allGratuitProduct, "code_gratuit")}
                  ></ConfirmCommande>
                  <Link to="/" className="btn btn-light btn-block">
                    Continuer vos achats
                  </Link>
                  <Link to="/cart" className="btn btn-light btn-block" onClick={handleDeleteAllCart}>
                    <i className="fa fa-trash" />
                    <span className="text">Vider Panier</span>
                  </Link>
                </div>
              </aside>
            </div>
          </div>
          <br />
          {inputList.length > 0 && (
            <>
              {/* <Recommended sousfamilleCode = {inputList[0].sousfamille_code}></Recommended> */}
              <PopularProduct gammeCode={inputList[0].gamme_code}></PopularProduct>
            </>
          )}
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = (state) => {
  return { products: state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    DeleteCart: (id) => {
      dispatch(actionCreators.DeleteCart(id));
    },
    DeleteAllCart: (id) => {
      dispatch(actionCreators.DeleteAllCart(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);

var _ = require("underscore");

/**
 * @param {Object} arrayObject
 * @returns
 */
export const sortByPrice = (arrayObject) => {
  const resultObject = _.filter(arrayObject, (el) => el.prix_ttc2 > 0);
  return _.sortBy(resultObject, "prix_ttc2");
};

/**
 * sort object by created_at
 * remove item if created_at not null
 * @param {Object} arrayObject
 * @returns
 */
export const sortByDate = (arrayObject) => {
  const resultObject = _.filter(arrayObject, (el) => el.created_at != "null");
  return _.sortBy(resultObject, "created_at");
};

/**
 * filter by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterByWord = (arrayObject, string) => {
  return arrayObject.filter((el) => el.libelle.toLowerCase().includes(string.toLowerCase()));
};

/**
 * sort product by price asc
 * @param {Object} arrayObject
 * @returns
 */
export const sortPriceAsc = (arrayObject) => {
  const resultObject = _.filter(arrayObject, (el) => el.prix_ttc2 > 0);
  return resultObject.sort(function (a, b) {
    return a.prix_ttc2 - b.prix_ttc2;
  });
};
/**
 * sort product by price desc
 * @param {Object} arrayObject
 * @returns
 */
export const sortPriceDesc = (arrayObject) => {
  const resultObject = _.filter(arrayObject, (el) => el.prix_ttc2 > 0);
  return resultObject.sort(function (a, b) {
    return b.prix_ttc2 - a.prix_ttc2;
  });
};
/**
 * sort product by date asc
 * @param {Object} arrayObject
 * @returns
 */
export const sortDateAsc = (arrayObject) => {
  const resultObject = _.filter(arrayObject, (el) => el.created_at != "null" && el.created_at != null);
  return resultObject.sort(function (a, b) {
    return new Date(a.created_at) - Date(b.created_at);
  });
};

/**
 * check product price range
 * @param {Object} arrayObject
 * @param {String} price1
 * @param {String} price2
 * @returns
 */
export const priceBetween = (arrayObject, price1, price2) => {
  const resultObject = _.filter(arrayObject, (el) => el.prix_ttc2 > 0);
  return resultObject.filter((el) => el.prix_ttc2 > price1 && el.prix_ttc2 < price2);
};

/**
 * map to object to get product img
 * @param {Object} arrayObject
 * @param {string} string
 */
export const getProductImg = (arrayObject, code) => {
  let newObject = arrayObject.filter((el) => el.code == code);
  return newObject[0].image;
};

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getAllGammes } from "../../services/GammeApi";
import { getAllFamilles } from "../../services/FamilleApi";
import ErrorAlert from "./../../others/ErrorAlert";
import CustomSyncLoader from "./../../others/CustomSyncLoader";
import { getEntrepriseDetails } from "../../services/EntrepriseApi";

function Footer() {
  const [filtredGamme, setfiltredGamme] = useState([]);
  const [filtredFamille, setfiltredFamille] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);

  useEffect(() => {
    setLoading(true);
    getAllFamilles()
      .then((res) => {
        setfiltredFamille(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));

    getEntrepriseDetails()
      .then((res) => {
        setdata(res.data[0]);
        console.log(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setLoading(true);
    getAllGammes()
      .then((res) => {
        setfiltredGamme(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  return (
    <footer className="section-footer bg-secondary border-top">
      <div className="container">
        {loading && (
          <div className="container mt-5">
            <div className="d-flex align-items-center justify-content-center h-100">
              <CustomSyncLoader></CustomSyncLoader>
            </div>
          </div>
        )}
        {!loading && error && (
          <div>
            <ErrorAlert msg={`Erreur: ${error}`}></ErrorAlert>
          </div>
        )}
        {!loading && !error && filtredFamille && filtredGamme && (
          <section className="footer-top padding-y text-white">
            <div className="row">
              <aside className="col-md col-6">
                <h6 className="title" style={{ color: "#fff" }}>
                  Gamme
                </h6>
                <ul className="list-unstyled">
                  {filtredGamme.slice(0, 4).map((el, index) => (
                    <li key={index}>
                      <Link to={"/filtergamme/" + el.code}>{el.libelle}</Link>
                    </li>
                  ))}
                </ul>
              </aside>
              <aside className="col-md col-6">
                <h6 className="title" style={{ color: "#fff" }}>
                  Famille
                </h6>
                <ul className="list-unstyled">
                  {filtredFamille.slice(0, 4).map((el, index) => (
                    <li key={index}>
                      <Link to={"/filterfamille/" + el.code}>{el.libelle}</Link>
                    </li>
                  ))}
                </ul>
              </aside>
              <aside className="col-md col-6">
                <h6 className="title"></h6>
                <ul className="list-unstyled">
                  {filtredFamille.slice(4, 8).map((el, index) => (
                    <li key={index}>
                      <Link to={"/filterfamille/" + el.code}>{el.libelle}</Link>
                    </li>
                  ))}
                </ul>
              </aside>
              <aside className="col-md col-6">
                <h6 className="title" style={{ color: "#fff" }}>
                  Compte
                </h6>
                <ul className="list-unstyled">
                  <li>
                    <Link to="/login">S'identifier</Link>
                    {/* <a href="#"> User Login </a> */}
                  </li>
                  <li>
                    <Link to="/register">S'inscrire</Link>
                    {/* <a href="#"> User register </a> */}
                  </li>
                  <li>
                    <Link
                      to={
                        localStorage.getItem("isLoginNomashop") == "false" || !localStorage.getItem("isLoginNomashop")
                          ? "/login"
                          : "/account"
                      }
                    >
                      Compte
                    </Link>
                    {/* <a href="#"> Account Setting </a> */}
                  </li>
                  <li>
                    <Link to="/cart">Mon panier</Link>
                  </li>
                  <li>
                    <Link to="/contact">Nous contacter</Link>
                  </li>
                </ul>
              </aside>
              <aside className="col-md">
                <h6 className="title" style={{ color: "#fff" }}>
                  Social
                </h6>
                <ul className="list-unstyled">
                  <li>
                    <a href={data.facebook}>
                      <i className="fab fa-facebook" /> Facebook
                    </a>
                  </li>
                  <li>
                    <a href={data.linkedin}>
                      <i className="fab fa-linkedin" /> Linkedin
                    </a>
                  </li>
                </ul>
              </aside>
            </div>
          </section>
        )}

        <section className="footer-bottom border-top row">
          <div className="col-md-2">
            <a href="http://www.clediss.com">© 2021 Clediss</a>
          </div>
          <div className="col-md-8 text-md-center">
            <span className="px-2">{data.email}</span>
            <span className="px-2">{data.telephone}</span>

            <span className="px-2">{data.adresse}</span>
          </div>
          <div className="col-md-2 text-md-right text-muted">
            <i className="fab fa-lg fa-cc-visa" />
            {/* <i className="fab fa-lg fa-cc-paypal" /> */}
            <i className="fab fa-lg fa-cc-mastercard" />
          </div>
        </section>
      </div>
      {/* //container */}
    </footer>
  );
}

export default Footer;

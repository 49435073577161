import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { addCommande, getParamCommande } from "../../services/CommandeApi";
import {
  calculateRemiseEntete,
  calculateTotalHt,
  calculateTotalTTc,
  calculateTotalTva,
  multiplicateNumber,
} from "../../Utils/ObjectOperation";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { isLogin } from "../../Utils/SessionUtils";
import { calculateMontantRemise, calculateRemise } from "../../Utils/CustumObjectOperation";
import CustomSyncLoader from "../../others/CustomSyncLoader";
import ErrorAlert from "./../../others/ErrorAlert";
/**
 * Confirm Commande button
 * @param {Object} props
 * @returns
 */
function ConfirmCommande(props) {
  const history = useHistory();
  const [latitude, setlatitude] = useState("");
  const [longitude, setlongitude] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setshowModal] = useState(false);

  const [clientEmail, setclientEmail] = useState("");
  const [clientNom, setclientNom] = useState("");
  const [clientMobile, setclientMobile] = useState("");
  const [clientAdresse, setclientAdresse] = useState("");

  const [clientCode, setclientCode] = useState("");
  const [societeCode, setsocieteCode] = useState("");
  const [soussocieteCode, setsoussocieteCode] = useState("");
  const [userCode, setuserCode] = useState("");
  const [remiseClient, setremiseClient] = useState("");
  const [depotCode, setdepotCode] = useState("");

  useEffect(() => {
    /** get latitude and longitude */
    const user = JSON.parse(localStorage.getItem("user"));
    if (isLogin()) {
      setclientEmail(user.email);
      setclientMobile(user.mobile);
      setclientNom(user.nom);
      setclientAdresse(user.adresse_facturation);

      setclientCode(user.code);
      setsocieteCode(user.societe_code);
      setsoussocieteCode(user.soussociete_code);
      setuserCode("nomashop");
      setremiseClient(user.remise_client ? user.remise_client : 0);
      setdepotCode("nomashop");
    } else {
      getParamCommande().then((res) => {
        setclientCode(res.data[0].client_code);
        setsocieteCode(res.data[0].societe_code);
        setsoussocieteCode(res.data[0].soussociete_code);
        setuserCode(res.data[0].user_code);
        setremiseClient(0);
        setdepotCode(res.data[0].depot_code);
      });
    }

    navigator.geolocation.getCurrentPosition(function (position) {
      setlatitude(position.coords.latitude);
      setlongitude(position.coords.longitude);
    });
  }, []);
  /**
   * onclick confirm order button event
   */
  const handleConfirmCommand = () => {
    //test if user is login
    if (!isLogin()) {
      //navigate to login
      //history.push("/login");
      setshowModal(true);
    } else {
      //setshowModal(true);
      //alert("yes connected");
      constructCommande();
    }
  };

  const constructCommande = () => {
    // all product list
    const commande = props.commande;
    // generate new code sequence
    const codeEntete = parseInt(Date.now() * Math.random());
    // user object from local storage
    const user = JSON.parse(localStorage.getItem("user"));
    // flat commande object
    const flatedObject = props.gratuits.flat();
    // map commande object
    //add prix_ht and initial_remise_promotion
    flatedObject.map((el) => ((el["prix_ht"] = 0), (el["initial_remise_promotion"] = 100)));
    //merge  maped and flated commande object
    const mergedCommande = [...flatedObject, ...commande];

    //ligne de commande
    const lignes = mergedCommande.map((el) => ({
      produit_code: el.code,
      configuration: "N",
      date_creation: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      echantillon: 0,
      email: 0, //0
      enpromotion: 0,
      entetecommercial_code: codeEntete, //add prfix
      netAPayer:
        multiplicateNumber(isLogin() ? el.prix_vente_nomashop_2 : el.prix_vente_nomashop_1, el.cartQuantity) -
        calculateMontantRemise(
          multiplicateNumber(isLogin() ? el.prix_vente_nomashop_2 : el.prix_vente_nomashop_1, el.cartQuantity),
          el.initial_remise_promotion ?? 0
        ),
      note: "0",
      num_config: "4009041102663",
      num_doc: "",
      num_serie: null,
      p_tva: el.tva,
      presente: "0",
      prix_achat_ttc: el.prix_achat_ttc,
      pu_ht: el.prix_ht,
      pu_ttc: isLogin() ? el.prix_vente_nomashop_2 : el.prix_vente_nomashop_1,
      quantite: el.cartQuantity,
      remise: el.initial_remise_promotion ?? 0, //remise produit
      remise_client: remiseClient, //remise client
      taux_tva: el.tva,
      total: multiplicateNumber(isLogin() ? el.prix_vente_nomashop_2 : el.prix_vente_nomashop_1, el.cartQuantity),
      total_net:
        multiplicateNumber(isLogin() ? el.prix_vente_nomashop_2 : el.prix_vente_nomashop_1, el.cartQuantity) -
        calculateMontantRemise(
          multiplicateNumber(isLogin() ? el.prix_vente_nomashop_2 : el.prix_vente_nomashop_1, el.cartQuantity),
          el.initial_remise_promotion ?? 0
        ), //remise
      total_ht: multiplicateNumber(el.prix_ht, el.cartQuantity),
      total_net_ht:
        multiplicateNumber(el.prix_ht, el.cartQuantity) -
        calculateMontantRemise(multiplicateNumber(el.prix_ht, el.cartQuantity), el.initial_remise_promotion ?? 0), //remise
      type: "commande",
    }));

    // consturct commande object
    const commandeObject = {
      adresse: clientAdresse,
      annule: "",
      cause_non_vente: "0",
      client_code: clientCode,
      code: codeEntete,
      code_commande: codeEntete,
      commentaire: "",
      date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      date_prevu_livraison: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      depot_code: depotCode,
      hors_client: "0",
      isAvoired: false,
      latitude: latitude,
      lignes: lignes,
      longitude: longitude,
      mode_paiement: "",
      montant_total_ht: calculateTotalHt(lignes), //sum prix ht * qte
      montant_total_ttc: calculateTotalTTc(lignes), //sum prix ttc * qte
      montant_total_tva: calculateTotalTva(lignes), // ttc - ht
      montant_retenue: 0.0,
      net_a_payer: (calculateTotalTTc(lignes) - calculateRemiseEntete(lignes)).toFixed(3), // ttc - remise
      produit_annonce_non_vendu: "0",
      remise: (calculateTotalTTc(lignes) - (calculateTotalTTc(lignes) - calculateRemiseEntete(lignes))).toFixed(3), //total - net
      remiseEspece: 0.0,
      remise_commande: 0.0,
      remise_pied: remiseClient, //remise client
      signature_client: "",
      societe_code: societeCode,
      solde: 0.0,
      soussociete_code: soussocieteCode,
      type: "commande",
      user_code: userCode,
      visite_code: "1-3C10003",
      visites: [],
      nomashop: "1",
      client_mobile: clientMobile,
      client_nom: clientNom,
      client_email: clientEmail,
    };

    //test if pannel is empty
    if (mergedCommande.length === 0) {
      alert("votre panier est vide");
    } else {
      //hide laoding
      setLoading(true);
      //call axios function to submit commande
      console.log(commandeObject);
      addCommande(commandeObject)
        .then((res) => {
          //set time after submit commande
          setTimeout(() => {
            setLoading(false);
            //navigate to commande*
            if (isLogin()) {
              history.push("/commande");
            } else {
              history.push("/codecommande/" + codeEntete);
            }
          }, 2000);
        })
        .catch((error) => setError(error));
    }
  };

  return (
    <>
      {loading && (
        <div className="container mt-5">
          <div className="d-flex align-items-center justify-content-center h-100">
            <CustomSyncLoader></CustomSyncLoader>
          </div>
        </div>
      )}
      {!loading && error && (
        <div>
          <ErrorAlert msg={`Erreur: ${error}`}></ErrorAlert>
        </div>
      )}
      {!loading && !error && (
        <>
          <Link
            to="#"
            className="btn btn-primary btn-block"
            onClick={handleConfirmCommand}
            data-toggle="modal"
            data-target="#exampleModalLong"
          >
            Confirmer commande
          </Link>
          {showModal && (
            <div
              className="modal fade"
              id="exampleModalLong"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                      Information commande
                    </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Nom</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="..."
                          onChange={(e) => setclientNom(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Mobile</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="..."
                          onChange={(e) => setclientMobile(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">email</label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="..."
                          onChange={(e) => setclientEmail(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Adresse</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="..."
                          onChange={(e) => setclientAdresse(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">
                      Annuler
                    </button>
                    <button type="button" className="btn btn-primary" onClick={constructCommande} data-dismiss="modal">
                      Valider commande
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default ConfirmCommande;

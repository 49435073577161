import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllCommandes } from "../../services/CommandeApi";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import ErrorAlert from "./../../others/ErrorAlert";
import CustomSyncLoader from "./../../others/CustomSyncLoader";

function Orders(props) {
  const history = useHistory();

  const [currentData, setcurrentData] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [dataPerPages, setdataPerPages] = useState(3);
  const [pageNumbers, setpageNumbers] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);

  useEffect(() => {
    setLoading(true);
    getAllCommandes()
      .then((res) => {
        setdata(res.data);
        paginateData(currentPage, res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  const paginateData = (currentPage, allDataToPaginate) => {
    // calculate first index 1 * 9 = 9
    const indexOfLastPost = currentPage * dataPerPages;
    // calculate last index = 9 - 9 = 0
    const indexOfFirstPost = indexOfLastPost - dataPerPages;
    //ajouter list product to currentData
    // currentData = products.slice(0,9)
    setcurrentData(allDataToPaginate.slice(indexOfFirstPost, indexOfLastPost));

    // math.ceil = returns the smallest integer greater than or equal to the given number
    const pageNumbersCount = [];
    // math.ceil = returns the smallest integer greater than or equal to the given number
    // console.log(Math.ceil(2.95))  ===>  2
    for (let i = 1; i <= Math.ceil(allDataToPaginate.length / dataPerPages); i++) {
      // count page number for pagination [1,2,3,4,5,6,7,8,9]
      pageNumbersCount.push(i);
    }
    // add list of pagination number
    setpageNumbers(pageNumbersCount);
  };

  /**
   * onclick in page number
   * @param {event} pageNumber
   */
  const paginate = (pageNumber) => {
    //update pagination
    paginateData(pageNumber, data);
    //update current page of pagination
    setcurrentPage(pageNumber);
  };

  //next page onclick
  const nextPage = () => {
    //update pagination
    paginateData(currentPage + 1, data);
    //update current page of pagination
    setcurrentPage(currentPage + 1);
  };
  // previous page onclick
  const previousPage = () => {
    //update pagination
    paginateData(currentPage + 1, data);
    //update current page of pagination
    setcurrentPage(currentPage - 1);
  };

  const handleLogout = () => {
    localStorage.setItem("isLoginNomashop", false);
    history.push("/login");
  };
  return (
    <div>
      {loading && (
        <div className="container mt-5">
          <div className="d-flex align-items-center justify-content-center h-100">
            <CustomSyncLoader></CustomSyncLoader>
          </div>
        </div>
      )}
      {!loading && error && (
        <div>
          <ErrorAlert msg={`Erreur: ${error}`}></ErrorAlert>
        </div>
      )}
      {!loading && !error && data && (
        <section className="col-12 section-content padding-y bg">
          <div className="container">
            <div className="row">
              <aside className="col-md-3">
                {/*   SIDEBAR   */}
                <ul className="list-group">
                  <a className="list-group-item active" href="#">
                    Historiques des commandes
                  </a>
                </ul>
                <br />
                <a className="btn btn-light btn-block" href="#">
                  <i className="fa fa-power-off" />
                  <span className="text" onClick={handleLogout}>
                    Déconnection
                  </span>
                </a>
                {/*   SIDEBAR .//END   */}
              </aside>
              <main className="col-md-9">
                {currentData.map((item, index) => (
                  <article key={item.id} className="card">
                    <header className="card-header">
                      <strong className="d-inline-block mr-3">Code commande : {item.code}</strong>
                      <span>Date commande: {item.date}</span>
                      <Link to={"/trackorder/" + item.code_commande} className="btn btn-light float-right">
                        Suivi Commande
                      </Link>
                    </header>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-8">
                          <h6 className="text-muted">Livraison à</h6>
                          <p>
                            {item.client.nom} {item.client.prenom} <br />
                            Mobile {item.client.mobile} Email:
                            {item.client.email} <br />
                            Location: {item.client.adresse_facturation}
                            {item.client.magasin} <br />
                            P.O. Box: 100123
                          </p>
                        </div>
                        <div className="col-md-4">
                          {/* <h6 className="text-muted">Payment</h6>
                             <span className="text-success">
                               <i className="fab fa-lg fa-cc-visa" />
                               Visa **** 4216
                             </span> */}

                          {item.remise == 0 ? (
                            <></>
                          ) : (
                            <>
                              Remise Total: {item.remise} DT <br />
                            </>
                          )}
                          <br />

                          {item.remise == 0 ? (
                            <></>
                          ) : (
                            <>
                              Remise Pied: {item.remise_pied} % <br />
                            </>
                          )}
                          <br />

                          <p>
                            Total HT: {item.montant_total_ht} DT <br />
                            Total TVA: {item.montant_total_tva} DT <br />
                            Total TTC: {item.montant_total_ttc} DT <br />
                            <span className="b">Total NET: {item.net_a_payer} DT</span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <article className="filter-group">
                      <header className="card-header">
                        <a href="#" data-toggle="collapse" data-target="#collapse_5" aria-expanded="false">
                          <i className="icon-control fa fa-chevron-down" />
                          <h6 className="title">Voir détail commande </h6>
                        </a>
                      </header>
                      <div className="filter-content collapse in" id="collapse_5" style={{}}>
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table table-hover">
                              <tbody>
                                {currentData[index].lignes.map((el) => (
                                  <tr key={el.id}>
                                    <td width={65}>
                                      <img src={"/images/product/product.png"} alt="" className="img-xs border" />
                                    </td>
                                    <td>
                                      <p className="title mb-0">{el.produit_libelle}</p>
                                      <var className="price text-muted">{el.pu_ttc} DT</var>
                                      <var className="price text-muted">remise: {el.remise} %</var>
                                    </td>
                                    <td>
                                      X {el.quantite} <br /> {el.total_net} DT
                                    </td>
                                    <td width={250}>
                                      <Link to={"/detail/" + el.produit_code} className="btn btn-outline-primary">
                                        Voir produit
                                      </Link>
                                      {/* <Link to={"/detail/" + el.produit_code} className="btn btn-light">
                                     Detail
                                   </Link> */}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {/* card-body.// */}
                      </div>
                    </article>
                  </article>
                ))}
                <nav className="mt-4" aria-label="Page navigation sample">
                  <ul className="pagination pagination-responsive pagination-sm">
                    <li className={currentPage == 1 ? "page-item disabled" : "page-item"}>
                      <Link to="/commande" onClick={previousPage} className="page-link">
                        Précédent
                      </Link>
                    </li>
                    {pageNumbers.map((el, index) => (
                      <li key={index} className={el == currentPage ? "page-item active" : "page-item"}>
                        <Link to="/commande" onClick={() => paginate(el)} className="page-link" href="#">
                          {el}
                        </Link>
                      </li>
                    ))}
                    <li className="page-item">
                      <Link to="/commande" onClick={nextPage} className="page-link">
                        Suivant
                      </Link>
                    </li>
                  </ul>
                </nav>
              </main>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return { products: state.products };
};

export default connect(mapStateToProps)(Orders);

import React from "react";
import { Link } from "react-router-dom";
import styles from "./ButtomNavigation.module.css";

function ButtomNavigation() {
  return (
    <div className={styles.hide}>
      <nav className={styles.mobile_bottom_nav}>
        {/* <div className={styles.mobile_bottom_nav__item}>
          <div className={styles.mobile_bottom_nav__item_content}>
            <Link to="/productgrid">
              <i className="fas fa-list fa-lg"></i>
            </Link>
          </div>
        </div> */}
        <div className={styles.mobile_bottom_nav__item}>
          <div className={styles.mobile_bottom_nav__item_content}>
            <Link to="/">
              <i className="fas fa-home fa-lg"></i>
            </Link>
          </div>
        </div>
        <div className={styles.mobile_bottom_nav__item}>
          <div className={styles.mobile_bottom_nav__item_content}>
            <Link to="/cart">
              <i className="fa fa-shopping-cart fa-lg"></i>
            </Link>
          </div>
        </div>
        <div className={styles.mobile_bottom_nav__item}>
          <div className={styles.mobile_bottom_nav__item_content}>
            <Link
              to={
                localStorage.getItem("isLoginNomashop") == "false" ||
                !localStorage.getItem("isLoginNomashop")
                  ? "/login"
                  : "/account"
              }
            >
              <i className="fa fa-user fa-lg"></i>
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default ButtomNavigation;

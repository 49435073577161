import axios from "axios";
require("dotenv").config();


const api_url = process.env.REACT_APP_API_ENDPOINT;

/**
 * get all gammes
 */
export async function getAllGammes() {
  try {
    const response = await axios.get(api_url + "gammes", {
      withCredentials: true,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}


/**
 * get product by gammes
 */
export async function getProductByGammes(id) {
  try {
    const response = await axios.get(api_url + "produits/productListByColumn/gammes/"+id, {
      withCredentials: true,
    });
    //var data_filter = response.data.filter(el => el.vendable_web == "1")
    return response;
  } catch (error) {
    console.error(error);
  }
}
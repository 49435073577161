import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
/**
 * custum loader
 * @returns 
 */
export default function CustomSyncLoader() {
  return <ScaleLoader
 size={60} color={"#123abc"} />;
}

import React, { useState } from "react";
import { useHistory } from "react-router-dom";

function SearchForm() {
  const history = useHistory();
  const [text, setText] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    if(text.length>0){
      history.push("/search/" + text);
      history.go(0);
    }
   
  };

  return (
    <div className="col-lg  col-md-6 col-sm-12 col">
      <form action="#" className="search" onSubmit={handleSearch}>
        <div className="input-group w-100">
          <input
            type="text"
            className="form-control"
            placeholder="Recherche ..."
            value={text}
            onChange={(e) => setText(e.target.value)}
          />

          <div className="input-group-append">
            <button className="btn btn-primary" type="submit">
              <i className="fa fa-search" />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default SearchForm;

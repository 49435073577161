import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import CustomSyncLoader from "../../others/CustomSyncLoader";
import { getProductBySousFamilles } from "../../services/SousFamilleApi";
import ErrorAlert from "./../../others/ErrorAlert";
import * as actionCreators from "../../actions/index";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isLogin } from "../../Utils/SessionUtils";

function Recommended(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);

  useEffect(() => {
    setLoading(true);
    getProductBySousFamilles(props.sousfamilleCode)
      .then((res) => {
        setdata(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  /**
   * add product to cart
   * @param {Integer} id
   */
  const handleClick = (item) => {
    props.addToCart(item);
    toast(" 🛒 Produit ajouté");
  };

  /**
   * navigate to product detail by code
   * @param {string} value
   */
  const handleProductClick = (value) => {
    history.push("/detail/" + value);
    history.go(0);
  };

  return (
    <output>
      <section className="section-content mt-4">
        <div className="container">
          <div className="card card-body">
            <h4 className="card-title mb-2">Recommandation</h4>
            {loading && (
              <div className="container mt-5">
                <div className="d-flex align-items-center justify-content-center h-100">
                  <CustomSyncLoader></CustomSyncLoader>
                </div>
              </div>
            )}
            {!loading && error && (
              <div>
                <ErrorAlert msg={`Erreur: ${error}`}></ErrorAlert>
              </div>
            )}
            {!loading && !error && data && (
              <div className="row">
                {data.slice(0, 8).map((el) => (
                  <div key={el.id} className="col-md-3">
                    <figure className="itemside mb-4">
                      <div className="aside">
                        <img
                          src={el.image == null || el.image == "" ? "/images/product/product.png" : el.image}
                          className="img-sm"
                        />
                      </div>
                      <figcaption className="info align-self-center">
                        <Link to="#" className="title" onClick={() => handleProductClick(el.code)}>
                          {el.libelle}
                        </Link>
                        <strong className="price">
                          {isLogin() ? el.prix_vente_nomashop_2 : el.prix_vente_nomashop_1} DT
                        </strong>
                        <Link to="#" className="btn btn-outline-primary btn-sm" onClick={() => handleClick(el)}>
                          Ajouter au panier
                          <i className="fa fa-shopping-cart" />
                        </Link>
                      </figcaption>
                    </figure>
                  </div>
                ))}
                <ToastContainer></ToastContainer>
              </div>
            )}
          </div>
        </div>
      </section>
    </output>
  );
}

const mapStateToProps = (state) => {
  return { products: state };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (item) => {
      dispatch(actionCreators.addToCart(item));
    },
    addToFavoris: (item) => {
      dispatch(actionCreators.addToFavoris(item));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Recommended);

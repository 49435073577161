/**
 * return localstorage value
 */
export function getSession(value) {
  return localStorage.getItem(value);
}

export const isLogin = () => {
  if (
    getSession("isLoginNomashop") &&
    getSession("isLoginNomashop") == "true"
  ) {
    return true;
  }
  return false;
};

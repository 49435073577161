import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CustomSyncLoader from "../../others/CustomSyncLoader";
import { connect } from "react-redux";
import * as actionCreators from "../../actions/index";
import { loadProducts, addToCart } from "../../actions/index";
import { groupedByFamille, groupedByGamme } from "../../Utils/GroupByObject";
import { filterByWord, priceBetween, sortDateAsc, sortPriceAsc, sortPriceDesc } from "../../Utils/SortObject";
import ProductCardFull from "../Card/ProductCardFull";

function ProductGrid(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [search, setsearch] = useState("");

  const [currentData, setcurrentData] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [dataPerPages, setdataPerPages] = useState(9);
  const [pageNumbers, setpageNumbers] = useState([]);
  const [data, setdata] = useState([]);
  const [sortProduct, setsortProduct] = useState("1");

  const [min, setmin] = useState("");
  const [max, setmax] = useState("");

  const { products } = props;

  useEffect(() => {
    // load products list from redux
    props.loadProducts();
  }, []);

  useEffect(() => {
    setLoading(true);

    if (Object.entries(products).length === 0) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    // default paginate
    // product list
    paginateData(currentPage, products);
    setdata(products);
  }, [products]);

  const paginateData = (currentPage, allDataToPaginate) => {
    // calculate first index 1 * 9 = 9
    const indexOfLastPost = currentPage * dataPerPages;
    // calculate last index = 9 - 9 = 0
    const indexOfFirstPost = indexOfLastPost - dataPerPages;
    //ajouter list product to currentData
    // currentData = products.slice(0,9)
    setcurrentData(allDataToPaginate.slice(indexOfFirstPost, indexOfLastPost));

    // math.ceil = returns the smallest integer greater than or equal to the given number
    const pageNumbersCount = [];
    // math.ceil = returns the smallest integer greater than or equal to the given number
    // console.log(Math.ceil(2.95))  ===>  2
    for (let i = 1; i <= Math.ceil(allDataToPaginate.length / dataPerPages); i++) {
      // count page number for pagination [1,2,3,4,5,6,7,8,9]
      pageNumbersCount.push(i);
    }
    // add list of pagination number
    setpageNumbers(pageNumbersCount);
  };

  /**
   * onclick in page number
   * @param {event} pageNumber
   */
  const paginate = (pageNumber) => {
    //update pagination
    paginateData(pageNumber, data);
    //update current page of pagination
    setcurrentPage(pageNumber);
  };

  /**
   * add product id to redux
   * @param {integer} id
   */
  const handleClick = (id) => {
    props.addToCart(id);
  };

  const handleClickFavoris = (id) => {
    props.addToFavoris(id);
  };

  //next page onclick
  const nextPage = () => {
    //update pagination
    paginateData(currentPage + 1, data);
    //update current page of pagination
    setcurrentPage(currentPage + 1);
  };
  // previous page onclick
  const previousPage = () => {
    //update pagination
    paginateData(currentPage + 1, data);
    //update current page of pagination
    setcurrentPage(currentPage - 1);
  };

  /**
   * handle search event
   * @param {event} e
   */
  const handleSearch = (e) => {
    setsearch(e.target.value);

    // if search not empty
    if (e.target.value) {
      // filter by word
      let newList = filterByWord(products, e.target.value);
      setdata(newList);
      paginateData(currentPage, newList);
    }
  };

  /**
   * sort object
   * @param {event} e
   */
  const handleSortProduct = (e) => {
    setsortProduct(e.target.value);
    // if sort is price asc
    if (e.target.value === "2") {
      const sortAscPrice = sortPriceAsc(data);
      setdata(sortAscPrice);
      paginateData(currentPage, sortAscPrice);

      // if sort is price desc
    } else if (e.target.value === "3") {
      const sortDescPrice = sortPriceDesc(data);
      setdata(sortDescPrice);
      paginateData(currentPage, sortDescPrice);
      // if sort is date desc
    } else {
      const sortAscDate = sortDateAsc(data);
      //console.log(sortAscDate)
      setdata(sortAscDate);
      // TODO change to sortAscDate
      paginateData(currentPage, products);
    }
  };

  /**
   * filter proudct by gamme name
   * @param {string} gamme
   */
  const handleSelectGamme = (gamme) => {
    // group products by gamme
    // search by gamme name
    const searchGamme = groupedByGamme(products).filter((el) => el.GAMME === gamme);
    setdata(searchGamme[0].PRODUIT);
    paginateData(currentPage, searchGamme[0].PRODUIT);
  };

  /**
   * filter product by famille
   * @param {string} famille
   */
  const handleSelectFamille = (famille) => {
    // group products by famille
    // search by famille name
    const searchFamille = groupedByFamille(products).filter((el) => el.FAMILLE === famille);
    setdata(searchFamille[0].PRODUIT);
    paginateData(currentPage, searchFamille[0].PRODUIT);
  };

  /**
   * filter product by price
   */
  const handleConfirmPrice = () => {
    // filter by price
    const priceFilter = priceBetween(products, min, max);
    setdata(priceFilter);
    paginateData(currentPage, priceFilter);
  };

  return (
    <div>
      <section className="mt-4">
        {/* <div className="container">
          <h2 className="title-page">Catégories des produits</h2>
        </div> */}
      </section>
      <section className="section-content">
        <div className="container">
          <div className="row">
            <aside className="col-md-3">
              <div className="card">
                <article className="filter-group">
                  <header className="card-header">
                    <a href="#" data-toggle="collapse" data-target="#collapse_1" aria-expanded="false">
                      <i className="icon-control fa fa-chevron-down" />
                      <h6 className="title">Gamme</h6>
                    </a>
                  </header>
                  <div className="filter-content collapse show" id="collapse_1" style={{}}>
                    <div className="card-body">
                      <form className="pb-3">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Rechercher ..."
                            value={search || ""}
                            onChange={(e) => handleSearch(e)}
                          />
                          <div className="input-group-append">
                            <button className="btn btn-light" type="button">
                              <i className="fa fa-search" />
                            </button>
                          </div>
                        </div>
                      </form>
                      <ul className="list-menu">
                        {groupedByGamme(products).map((el, index) => (
                          <li key={index}>
                            <Link to="/productgrid" onClick={() => handleSelectGamme(el.GAMME)}>
                              {el.GAMME}
                            </Link>
                            <span className="badge badge-pill badge-light float-right">{el.PRODUIT.length}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    {/* card-body.// */}
                  </div>
                </article>
                {/* filter-group  .// */}
                <article className="filter-group">
                  <header className="card-header">
                    <a href="#" data-toggle="collapse" data-target="#collapse_2" aria-expanded="false">
                      <i className="icon-control fa fa-chevron-down" />
                      <h6 className="title">Famille </h6>
                    </a>
                  </header>
                  <div className="filter-content collapse show" id="collapse_2" style={{}}>
                    <div className="card-body">
                      {groupedByFamille(products).map((el, index) => (
                        <div key={index} className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            defaultChecked
                            onClick={() => handleSelectFamille(el.FAMILLE)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2"
                            onClick={() => handleSelectFamille(el.FAMILLE)}
                          >
                            {el.FAMILLE}
                          </label>

                          <b className="badge badge-pill badge-light float-right">{el.PRODUIT.length}</b>
                        </div>
                      ))}
                    </div>
                    {/* card-body.// */}
                  </div>
                </article>
                {/* filter-group .// */}
                <article className="filter-group">
                  <header className="card-header">
                    <a href="#" data-toggle="collapse" data-target="#collapse_3" aria-expanded="false">
                      <i className="icon-control fa fa-chevron-down" />
                      <h6 className="title">Prix </h6>
                    </a>
                  </header>
                  <div className="filter-content collapse show" id="collapse_3" style={{}}>
                    <div className="card-body">
                      {/* <input
                        type="range"
                        className="custom-range"
                        min={0}
                        max={1000}
                      /> */}
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label>Min</label>
                          <input
                            className="form-control"
                            placeholder="0 DT"
                            type="number"
                            value={min}
                            onChange={(e) => setmin(e.target.value)}
                          />
                        </div>
                        <div className="form-group text-right col-md-6">
                          <label>Max</label>
                          <input
                            className="form-control"
                            placeholder="1,0000 DT"
                            type="number"
                            value={max}
                            onChange={(e) => setmax(e.target.value)}
                          />
                        </div>
                      </div>
                      {/* form-row.// */}
                      <button className="btn btn-block btn-primary" onClick={handleConfirmPrice}>
                        Appliquer
                      </button>
                    </div>
                    {/* card-body.// */}
                  </div>
                </article>
                <article className="filter-group">
                  <header className="card-header">
                    <a href="#" data-toggle="collapse" data-target="#collapse_5" aria-expanded="false">
                      <i className="icon-control fa fa-chevron-down" />
                      <h6 className="title">Autres filters </h6>
                    </a>
                  </header>
                  <div className="filter-content collapse in" id="collapse_5" style={{}}>
                    <div className="card-body">
                      <label className="custom-control custom-radio">
                        <input type="radio" name="myfilter_radio" defaultChecked className="custom-control-input" />
                        <div className="custom-control-label">Any condition</div>
                      </label>
                      <label className="custom-control custom-radio">
                        <input type="radio" name="myfilter_radio" className="custom-control-input" />
                        <div className="custom-control-label">Brand new </div>
                      </label>
                      <label className="custom-control custom-radio">
                        <input type="radio" name="myfilter_radio" className="custom-control-input" />
                        <div className="custom-control-label">Used items</div>
                      </label>
                      <label className="custom-control custom-radio">
                        <input type="radio" name="myfilter_radio" className="custom-control-input" />
                        <div className="custom-control-label">Very old</div>
                      </label>
                    </div>
                    {/* card-body.// */}
                  </div>
                </article>
                {/* filter-group .// */}
              </div>
              {/* card.// */}
            </aside>
            {/* col.// */}
            <main className="col-md-9">
              <header className="border-bottom mb-4 pb-3">
                <div className="form-inline">
                  <span className="mr-md-auto">{data.length} Produits trouvés</span>
                  <select value={sortProduct} onChange={(e) => handleSortProduct(e)} className="mr-2 form-control">
                    <option value="1">Derniers produits</option>
                    <option value="2">Le moins cher</option>
                    <option value="3">Le plus cher</option>
                  </select>
                  <div className="btn-group">
                    <Link
                      to="/productgrid"
                      className="btn  btn-outline-secondary active"
                      data-toggle="tooltip"
                      title="Grid view"
                    >
                      <i className="fa fa-th" />
                    </Link>

                    <Link
                      to="/productlist"
                      className="btn btn-outline-secondary"
                      data-toggle="tooltip"
                      title="List view"
                    >
                      <i className="fa fa-bars" />
                    </Link>
                  </div>
                </div>
              </header>
              {/* sect-heading */}
              {loading && (
                <div className="container mt-5">
                  <div className="d-flex align-items-center justify-content-center h-100">
                    <CustomSyncLoader></CustomSyncLoader>
                  </div>
                </div>
              )}
              {!loading && error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
              {!loading && !error && products && (
                <div className="row">
                  <ProductCardFull itemsList={currentData}></ProductCardFull>
                </div>
              )}

              <nav className="mt-4" aria-label="Page navigation sample">
                <ul className="pagination pagination-responsive pagination-sm">
                  <li className={currentPage == 1 ? "page-item disabled" : "page-item"}>
                    <Link to="/productgrid" onClick={previousPage} className="page-link">
                      Précédent
                    </Link>
                  </li>
                  {pageNumbers.map((el, index) => (
                    <li key={index} className={el == currentPage ? "page-item active" : "page-item"}>
                      <Link to="/productgrid" onClick={() => paginate(el)} className="page-link">
                        {el}
                      </Link>
                    </li>
                  ))}
                  <li className="page-item">
                    <Link to="/productgrid" onClick={nextPage} className="page-link">
                      Suivant
                    </Link>
                  </li>
                </ul>
              </nav>
            </main>
            {/* col.// */}
          </div>
        </div>
        {/* container .//  */}
      </section>
    </div>
  );
}

//export default ProductGrid;

const mapStateToProps = (state) => {
  return { products: state.products };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToFavoris: (id) => {
      dispatch(actionCreators.addToFavoris(id));
    },
    loadProducts: () => dispatch(loadProducts()),
    addToCart: (id) => {
      dispatch(actionCreators.addToCart(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductGrid);

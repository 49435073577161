import React from "react";
import { Route } from "react-router-dom";

/**
 * private Router navigation
 * @param {Object} param0
 * @returns
 */
const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout>
        <Component {...props}></Component>
      </Layout>
    )}
  ></Route>
);

export default AppRoute;

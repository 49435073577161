import React from "react";
import { Link } from "react-router-dom";

function Banner2() {
  return (
    <div className="container">
      <section className="padding-bottom">
        <article className="box d-flex flex-wrap align-items-center p-5 bg-secondary">
          <div className="text-white mr-auto">
            <h3>Vous cherchez une catégorie ? </h3>
            <p> Articles populaires, remises, promotions et gratuités </p>
          </div>
          <div className="mt-3 mt-md-0">
            <Link to={"/category"} className="btn btn-outline-light">
              Voir plus
            </Link>
          </div>
        </article>
      </section>
    </div>
  );
}

export default Banner2;

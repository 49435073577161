const R = require("ramda");
/**
 * group product by gammme
 * @param {Oject} list
 * @returns
 */
export const groupedByGamme = (list) => {
  const gamme = R.groupBy(R.path(["gamme", "libelle"]));
  const groupedGamme = R.compose(
    R.map(R.zipObj(["GAMME", "PRODUIT"])),
    R.toPairs,
    gamme
  );
  return groupedGamme(list);
};

/**
 * group product by famille
 * @param {Object} list
 * @returns
 */
export const groupedByFamille = (list) => {
  const famille = R.groupBy(R.path(["famille", "libelle"]));
  const groupedFamille = R.compose(
    R.map(R.zipObj(["FAMILLE", "PRODUIT"])),
    R.toPairs,
    famille
  );
  return groupedFamille(list);
};

/**
 * group product by sous famille
 * @param {Object} list
 * @returns
 */
export const groupedBySousFamille = (list) => {
  const sousfamille = R.groupBy(R.path(["sousfamille", "libelle"]));
  const groupedSousFamille = R.compose(
    R.map(R.zipObj(["SOUS_FAMILLE", "PRODUIT"])),
    R.toPairs,
    sousfamille
  );
  return groupedSousFamille(list);
};

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { userLogin } from "../../services/UserApi";
import { useHistory } from "react-router-dom";

function SignIn() {
  const history = useHistory();
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    // localStorage.setItem("login",true)
    // history.push("/")
    userLogin(email, password).then((res) => {
      if (res.data) {
        localStorage.setItem("isLoginNomashop", true);
        localStorage.setItem("user", JSON.stringify(res.data.client));
        history.push("/");
      } else {
        //console.log("not exist")
        alert("email et/ou mot de passe incorrect(s)");
      }
      // localStorage.setItem("ss", res.headers["Set-Cookie"])
    });
  };

  return (
    <div>
      <section className="section-content padding-y bg">
        <div className="container">
          <div className="row">
            <aside className="col-md-3"></aside>
            <aside className="col-md-6">
              {/* ============================ COMPONENT LOGIN 1  ================================= */}
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-4">S'identifier</h4>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        className="form-control"
                        placeholder="ex. name@gmail.com"
                        type="email"
                        value={email || ""}
                        onChange={(e) => setemail(e.target.value)}
                      />
                    </div>
                    {/* form-group// */}
                    <div className="form-group">
                      {/* <a className="float-right" href="#">
                        Oublié
                      </a> */}
                      <label>Mot de passe</label>
                      <input
                        className="form-control"
                        placeholder="******"
                        type="password"
                        value={password || ""}
                        onChange={(e) => setpassword(e.target.value)}
                      />
                    </div>
                    {/* form-group// */}
                    {/* <div className="form-group">
                      <label className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          defaultChecked
                        />
                        <div className="custom-control-label">
                          Souviens-toi de moi
                        </div>
                      </label>
                    </div> */}
                    {/* form-group form-check .// */}
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        Se connecter
                      </button>
                    </div>
                    {/* form-group// */}
                  </form>
                </div>
                {/* card-body.// */}
                <div className="card-footer text-center">
                  avez-vous un compte? <Link to="/register">S'inscrire</Link>
                </div>
              </div>
            </aside>
            <aside className="col-md-3"></aside>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SignIn;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { isLogin } from "../../Utils/SessionUtils";
import SearchForm from "../Search/SearchForm";

function Header(props) {
  const history = useHistory();
  const [fullname, setfullname] = useState("");

  useEffect(() => {
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      setfullname(user.nom + user.prenom);
    }
  }, []);

  const logout = () => {
    localStorage.setItem("isLoginNomashop", false);
    localStorage.removeItem("user");
    history.push("/login");
  };

  return (
    <header className="section-header">
      <section className="header-main border-bottom">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-2 col-md-3 col-6">
              <Link to="/" className="brand-wrap">
                <img className="logo" src="images/logo.png" />
              </Link>
            </div>

            <SearchForm></SearchForm>
            {/* col.// */}
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="widgets-wrap float-md-right">
                <div className="widget-header mr-3">
                  <Link to="/cart" className="icon icon-sm rounded-circle bg-light">
                    <i className="fa fa-shopping-cart" />
                  </Link>

                  <span className="badge badge-pill badge-danger notify">{props.products.total}</span>
                </div>
                <div className="widget-header mr-3">
                  <Link to="/favoris" className="icon icon-sm rounded-circle bg-light">
                    <i className="fa fa-heart" />
                  </Link>

                  <span className="badge badge-pill badge-danger notify">{props.products.totalFavoris}</span>
                </div>

                <div className="widget-header dropdown">
                  <Link to="#" data-toggle="dropdown" className="dropdown-toggle" data-offset="20,10">
                    <div className="icon icon-sm rounded-circle bg-light ">
                      <i className="fa fa-user" />
                    </div>
                  </Link>
                  <div className="dropdown-menu dropdown-menu-right">
                    <Link to="/commande" className="dropdown-item">
                      Commandes
                    </Link>
                    <Link to="/searchorder" className="dropdown-item">
                      Suivi Commande
                    </Link>
                    <Link to="/statement" className="dropdown-item">
                      Relevés
                    </Link>
                    <hr className="dropdown-divider" />
                    <Link to="/account" className="dropdown-item" href="#">
                      Profile
                    </Link>
                  </div>
                </div>

                <div className="widget-header icontext ml-3">
                  <div className="text">
                    <span className="text-muted">Bienvenue!</span>
                    {isLogin() ? (
                      <div>
                        <Link to="/account">{fullname}</Link> |
                        <Link to="/login" onClick={logout}>
                          Déconnexion
                        </Link>
                      </div>
                    ) : (
                      <div>
                        <Link to="/login">S'identifier</Link> |<Link to="/register"> S'inscrire</Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </header>
  );
}

const mapStateToProps = (state) => {
  return { products: state };
};

export default connect(mapStateToProps)(Header);

import React, { useState, useEffect } from "react";

function Profile() {
  const [data, setdata] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      setdata(user);
    }
  }, []);

  return (
    <div>
      <section className="section-content padding-y bg">
        <div className="container">
          <div className="row">
            <aside className="col-md-3"></aside>
            <aside className="col-md-6">
              {/* ============================ COMPONENT PROFILE 2  ================================= */}
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-4">Profile</h4>
                  <form>
                    <div className="form-group">
                      <img
                        src={data.phote == null || data.photo == "null" ? "images/user.png" : data.photo}
                        className="img-sm rounded-circle border"
                      />
                    </div>
                    <div className="form-row">
                      <div className="col form-group">
                        <label>Nom</label>
                        <input
                          type="text"
                          className="form-control"
                          value = {data.nom || ""}
                        />
                      </div>
                      {/* form-group end.// */}
                      <div className="col form-group">
                        <label>Prénom</label>
                        <input
                          type="text"
                          className="form-control"
                          value = {data.prenom || ""}
                        />
                      </div>

                      {/* form-group end.// */}
                    </div>
                    {/* form-row.// */}
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        
                          <label>Email</label>
                          <input
                            type="email"
                            className="form-control"
                            value = {data.email}
                          />
                        
                      </div>
                      {/* form-group end.// */}
                      <div className="form-group col-md-6">
                        <label>Adresse</label>
                        <input type="text" className="form-control" value = {data.adresse || ""}/>
                      </div>
                      {/* form-group end.// */}
                    </div>
                    {/* form-row.// */}
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label>CIN</label>
                        <input
                          type="text"
                          className="form-control"
                          value={data.cin}
                        />
                      </div>
                      {/* form-group end.// */}
                      <div className="form-group col-md-6">
                        <label>Phone</label>
                        <input
                          type="text"
                          className="form-control"
                         value  ={data.mobile}
                        />
                      </div>
                      {/* form-group end.// */}
                    </div>
                    {/* form-row.// */}
                    {/* <button className="btn btn-primary btn-block">Send</button> */}
                  </form>
                </div>
                {/* card-body.// */}
              </div>
              {/* card .// */}
              {/* ============================ COMPONENT PROFILE END.// ================================= */}
            </aside>
            <aside className="col-md-3"></aside>
          </div>
          {/* row.// */}
          <br />
          <br />
        </div>
        {/* container .//  */}
      </section>
      {/* ========================= SECTION CONTENT END// ========================= */}
      {/* ========================= FOOTER ========================= */}
    </div>
  );
}

export default Profile;

import React from "react";
import { Link } from "react-router-dom";

function ContactUs() {
  return (
    <div>
      {/* ========================= SECTION PAGETOP ========================= */}
      <section className="section-pagetop bg-primary">
        <div className="container">
          <h2 className="title-page text-white">Nous contacter</h2>
        </div>
        {/* container //  */}
      </section>
      {/* ========================= SECTION INTRO END// ========================= */}
      {/* ========================= SECTION CONTENT ========================= */}
      <section className="section-content padding-y">
        <div className="container">
          <header className="section-heading">
            <h2 className="section-title">First section name</h2>
          </header>
          {/* sect-heading */}
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <p>
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
            nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <Link to="/" className="btn btn-light">
            « Retour
          </Link>
        </div>
        {/* container .//  */}
      </section>
      {/* ========================= SECTION CONTENT END// ========================= */}
      {/* ========================= SECTION  ========================= */}
      <section className="section-name bg padding-y">
        <div className="container">
          <header className="section-heading">
            <h2 className="section-title">Second section name</h2>
            <p className="lead">
              Life is easy why make it hard? Lorem ipsum dolor sit amet,
              consectetur adipisicing elit, sed do eiusmod.
            </p>
          </header>

          <div className="row">
            <aside className="col-lg-4">
              <div className="card">
                <div className="card-body">
                  <h6>Pricing sample</h6>
                  <div>
                    <strong className="h2">15.85 DT</strong> <span>/mois</span>
                  </div>
                  <small className="text-muted">
                    Get 20% off on first year
                  </small>
                  <hr />
                  <ul className="list-check">
                    <li>Lifetime free</li>
                    <li>Monthly free updates</li>
                    <li>Some great fearure </li>
                    <li>Latest trends</li>
                    <li>Another Feature Three</li>
                    <li>Some great fearure </li>
                    <li>Latest trends</li>
                    <li>Another Feature </li>
                  </ul>
                </div>
              </div>
            </aside>
            <aside className="col-lg-5">
              <h4 className="mb-3">Order form sample</h4>
              <p className="text-muted">
                When you enter into any new area of science, you almost always
                find yourself with a baffling new language.
              </p>
              <form>
                <div className="form-group">
                  <label>Full name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ex. John Doe"
                  />
                </div>
                <div className="form-row">
                  <div className="col-md-6 form-group">
                    <label>Phone</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type here"
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Type here"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Address</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Street name, Building, Office"
                  />
                </div>
                <div className="form-group">
                  <label className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customCheck1"
                    />
                    <div
                      className="custom-control-label"
                      htmlFor="customCheck1"
                    >
                      By clicking you agree to the Terms
                    </div>
                  </label>
                </div>
                <button className="btn btn-primary">Envoyer</button>
              </form>
            </aside>
          </div>
        </div>
        {/* container // */}
      </section>
    </div>
  );
}

export default ContactUs;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getAllFamilles } from "../../services/FamilleApi";
import { getAllGammes } from "../../services/GammeApi";
import { getAllSousFamilles } from "../../services/SousFamilleApi";
import ErrorAlert from "./../../others/ErrorAlert";
import CustomSyncLoader from "./../../others/CustomSyncLoader";

function Categories() {
  const [filtredGamme, setfiltredGamme] = useState([]);
  const [filtredFamille, setfiltredFamille] = useState([]);
  const [filtredSousFamille, setfiltredSousFamille] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    getAllFamilles()
      .then((res) => {
        setfiltredFamille(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
    getAllGammes()
      .then((res) => {
        setfiltredGamme(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
    getAllSousFamilles()
      .then((res) => {
        setfiltredSousFamille(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  return (
    <section className="section-content padding-y bg">
      <div className="container">
        {loading && (
          <div className="container mt-5">
            <div className="d-flex align-items-center justify-content-center h-100">
              <CustomSyncLoader></CustomSyncLoader>
            </div>
          </div>
        )}
        {!loading && error && (
          <div>
            <ErrorAlert msg={`Erreur: ${error}`}></ErrorAlert>
          </div>
        )}
        {!loading && filtredFamille && filtredGamme && (
          <div>
            <div className="row">
              {filtredGamme.map((el, index) => (
                <div key={index} className="col-md-4 mb-4">
                  <div className="card bg-dark">
                    <img style={{ height: "170px" }} src={"/images/banners/m4.jpg"} className="card-img opacity" />
                    <div className="card-img-overlay text-white">
                      <h5 className="card-title">{el.libelle}</h5>
                      {/* <p className="card-text">{el.PRODUIT.length} Produits</p> */}
                      <Link to={"/filtergamme/" + el.code} className="btn btn-light">
                        Découvrir
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <br />
            <div className="row">
              <div className="col-md-8">
                <div
                  className="card-banner overlay-gradient"
                  style={{
                    minHeight: 230,
                    backgroundImage: `url(${"/images/banners/m4.jpg"})`,
                  }}
                >
                  <div className="card-img-overlay white">
                    <h3 className="card-title">
                      Photography is my hobby <br /> right now
                    </h3>
                    <p className="card-text" style={{ maxWidth: 400 }}>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt.
                    </p>
                    <Link to={"/"} href="category" className="btn btn-warning">
                      Voir plus
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div
                  className="card-banner overlay-gradient"
                  style={{
                    minHeight: 230,
                    backgroundImage: `url(${"/images/banners/m5.jpg"})`,
                  }}
                >
                  <div className="card-img-overlay text-white">
                    <h5 className="card-title">Meilleur produit</h5>
                    <blockquote className="card-text">
                      Celui qui ne sait pas sourire ne dois pas ouvrir boutique.
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>

            <br />
            <br />
            <div className="row">
              {filtredFamille.map((el, index) => (
                <div key={index} className="col-md-4 mb-4">
                  <div className="card bg-dark">
                    <img
                      style={{ height: "170px" }}
                      src={el.image == null || el.image == "" ? "/images/banners/m3.jpg" : el.image}
                      className="card-img opacity"
                    />
                    <div className="card-img-overlay text-white">
                      <h5 className="card-title">{el.libelle}</h5>
                      {/* <p className="card-text">{el.PRODUIT.length} Produits</p> */}
                      <Link to={"/filterfamille/" + el.code} className="btn btn-light">
                        Découvrir
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default Categories;

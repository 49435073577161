/**
 * convert upperCase to lowerCase string
 * keep first char to upperCase
 * @param {string} string 
 * @returns 
 */
export const stringToLowwerCase = (string) => {
  var upper = string.toLowerCase();
  return upper.charAt(0).toUpperCase() + upper.slice(1);
};

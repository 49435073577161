import React from 'react'

function DownloadApp() {
    return (
        <section className="section-name padding-y bg">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h3>Téléchargez l'appli</h3>
              <p>Obtenez une application incroyable pour vous simplifier la vie</p>
            </div>
            <div className="col-md-6 text-md-right">
              <a href="https://play.google.com/store/apps?hl=fr&gl=US">
                <img src="images/misc/playmarket.png" height={40} />
              </a>
              {/* <a href="#">
                <img src="images/misc/appstore.png" height={40} />
              </a> */}
            </div>
          </div>
          {/* row.// */}
        </div>
        {/* container // */}
      </section>
    )
}

export default DownloadApp

import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { stringToLowwerCase } from "../../Utils/StringOperation";
import { getAllFamilles } from "../../services/FamilleApi";
import ErrorAlert from "./../../others/ErrorAlert";
import CustomSyncLoader from "./../../others/CustomSyncLoader";

function Navbar() {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);

  useEffect(() => {
    setLoading(true);
    getAllFamilles().then((res)=>{
      setdata(res.data)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      {loading && (
        <div className="container mt-5">
          <div className="d-flex align-items-center justify-content-center h-100">
            <CustomSyncLoader></CustomSyncLoader>
          </div>
        </div>
      )}
      {!loading && error && (
        <div>
          <ErrorAlert msg={`Erreur: ${error}`}></ErrorAlert>
        </div>
      )}
      {!loading && !error && data && (
    <nav className="navbar navbar-main navbar-expand-lg navbar-light border-bottom">


      <div className="container">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#main_nav"
          aria-controls="main_nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="main_nav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link to="/category" className="nav-link">
                Catégories
              </Link>
            </li>
            {data.slice(0, 8).map((el, i) => (
              <li key={i} className="nav-item">
                <Link to={"/filterfamille/" + el.code} className="nav-link">
                  {stringToLowwerCase(el.libelle)}
                </Link>
              </li>
            ))}
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
                href="#"
              >
                Autres
              </a>
              <div className="dropdown-menu">
                {data.slice(8, data.length).map((el, i) => (
                  <Link
                    to={"/filterfamille/" + el.code}
                    key={i}
                    className="dropdown-item"
                  >
                    {stringToLowwerCase(el.libelle)}
                  </Link>
                ))}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
      )}
 </>
  );
}

export default Navbar

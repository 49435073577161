import { calculateMontantRemise } from "./CustumObjectOperation";
import { isLogin } from "./SessionUtils";
const user = JSON.parse(localStorage.getItem("user"));

var _ = require("underscore");
/**
 * get sum of object list value
 * @param {Object} arrayObject
 * @returns
 */
export const sumCartPrice = (arrayObject) => {
  var sum = 0;
  for (var i = 0; i < arrayObject.length; i++) {
    sum += Number(arrayObject[i].cartPrice);
  }
  return sum.toFixed(3);
};

/**
 * get multiplicate two number
 * @param {string} value1
 * @param {string} value2
 * @returns
 */
export const multiplicateNumber = (value1, value2) => {
  return (Number(value1) * Number(value2)).toFixed(3);
};

/**
 * calculate total ht
 * @param {Object} arrayObject
 * @returns
 */
export const calculateTotalHt = (arrayObject) => {
  let total = arrayObject.reduce(function (acc, curr) {
    return acc + curr.quantite * curr.pu_ht;
  }, 0);
  return total.toFixed(3);
};

/**
 * calculate total ht
 * @param {Object} arrayObject
 * @returns
 */
export const calculateTotalTva = (arrayObject) => {
  let total = arrayObject.reduce(function (acc, curr) {
    return acc + (curr.p_tva / 100) * curr.pu_ht * curr.quantite;
  }, 0);
  return total.toFixed(3);
};

/**
 * Calculate Total ttc
 * @param {Object} arrayObject
 * @returns
 */
export const calculateTotalTTc = (arrayObject) => {
  let total = arrayObject.reduce(function (acc, curr) {
    return acc + curr.quantite * curr.pu_ttc;
  }, 0);
  return total.toFixed(3);
};

export const calculateRemiseEntete = (arrayObject) => {
  let totalEntete = arrayObject.reduce(function (acc, curr) {
    return acc + curr.quantite * curr.pu_ttc;
  }, 0);

  let remise = 0;
  if (isLogin()) {
    console.log(user.remise_client);
    const remiseClient = user.remise_client ? user.remise_client : 0;
    remise = calculateMontantRemise(totalEntete, remiseClient);
  } else {
    remise = calculateMontantRemise(totalEntete, 0);
  }

  let totalLigne = arrayObject.reduce(function (acc, el) {
    return (
      acc +
      Number(
        calculateMontantRemise(
          multiplicateNumber(el.pu_ttc, el.quantite),
          el.remise
        )
      )
    );
  }, 0);
  return (Number(remise) + totalLigne).toFixed(3);
};

export const sumSoldeStatement = (arrayObject) => {
  var solde = [];
  var sum = 0;
  for (let i = 0; i < arrayObject.length; i++) {
    sum += arrayObject[i].montant;
    solde.push(sum);
  }
  return solde;
};

export const getSumDebit = (arrayObject) => {
  let sum = arrayObject
    .filter((el) => el.sens_ecriture == "D")
    .reduce((s, f) => {
      return s + f.montant;
    }, 0);
  return sum;
};

export const getSumCredit = (arrayObject) => {
  let sum = arrayObject
    .filter((el) => el.sens_ecriture == "C")
    .reduce((s, f) => {
      return s + f.montant;
    }, 0);
  return sum;
};

export const getSumSolde = (arrayObject) => {
  let sum = arrayObject.reduce((s, f) => {
    return s + f;
  }, 0);
  return sum;
};

export const mergeObjectByPromotionCode = (list1, list2) => {
  let mergePromotion = [];
  for (let i = 0; i < list1.length; i++) {
    // Loop for array2
    for (let j = 0; j < list2.length; j++) {
      // Compare the element of each and
      // every element from both of the
      // arrays
      if (list1[i].code === list2[j].promotion_code) {
        // Return if common element found
        // merge two array object by code promotion
        const object3 = { ...list1[i], ...list2[j] };
        mergePromotion.push(object3);
      }
    }
  }
  return mergePromotion;
};

export const mergeObjectByGratuitsCode = (list1, list2) => {
  var mergeGratuits = [];
  // Loop for array1
  for (let i = 0; i < list1.length; i++) {
    // Loop for array2
    for (let j = 0; j < list2.length; j++) {
      // Compare the element of each and
      // every element from both of the
      // arrays
      if (list1[i].code === list2[j].gratuite_code) {
        // Return if common element found
        // merge two array object by code promotion
        const object3 = { ...list1[i], ...list2[j] };
        mergeGratuits.push(object3);
      }
    }
  }
  return mergeGratuits;
};

export function getUniqueListBy(arr, key) {
  return [...new Map(arr.map((item) => [item[0][key], item])).values()];
}

import React from "react";

function Features() {
  return (
    <section className="section-content padding-y-sm">
      <div className="container">
        <article className="card card-body">
          <div className="row">
            <div className="col-md-4">
              <figure className="item-feature">
                <span className="text-primary">
                  <i className="fa fa-2x fa-truck" />
                </span>
                <figcaption className="pt-3">
                  <h5 className="title">Qualité et Prix</h5>
                  <p>
                  Nous offrons des prix compétitifs sur notre large gamme de produits.
                  </p>
                </figcaption>
              </figure>
              {/* iconbox // */}
            </div>
            {/* col // */}
            <div className="col-md-4">
              <figure className="item-feature">
                <span className="text-primary">
                  <i className="fa fa-2x fa-landmark" />
                </span>
                <figcaption className="pt-3">

                  <h5 className="title">24/7 Centre d'Aide </h5>
                  <p>
                    A toute heure, vous pouvez contacter le Centre d'Aide pour une expérience d'achat facile.
                  </p>
                </figcaption>
              </figure>
              {/* iconbox // */}
            </div>
            {/* col // */}
            <div className="col-md-4">
              <figure className="item-feature">
                <span className="text-primary">
                  <i className="fa fa-2x fa-lock" />
                </span>
                <figcaption className="pt-3">
                <h5 className="title">Achetez en toute confiance</h5>
                  <p>
                  Notre Protection de l'acheteur vous protège du premier clic à la commande.
                  </p>
                </figcaption>
              </figure>
              {/* iconbox // */}
            </div>
            {/* col // */}
          </div>
        </article>
      </div>
      {/* container .//  */}
    </section>
  );
}

export default Features;

import React, { useEffect, useState } from "react";
import { getEntrepriseDetails } from "../../services/EntrepriseApi";

function TopNavbar() {
  const [data, setdata] = useState([]);

  useEffect(() => {
    getEntrepriseDetails().then((res) => {
      setdata(res.data[0]);
    });
  }, []);

  return (
    <nav className="navbar p-md-0 navbar-expand-sm navbar-light border-bottom">
      <div className="container">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTop4"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarTop4">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item dropdown">
              <a
                href="#"
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
              >
                Français
              </a>
              {/* <ul className="dropdown-menu small">
                <li>
                  <a className="dropdown-item" href="#">
                    English
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Arabic
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Russian
                  </a>
                </li>
              </ul> */}
            </li>
            <li className="nav-item dropdown">
              <a
                href="#"
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
              >
                TND
              </a>
              {/* <ul className="dropdown-menu small">
                <li>
                  <a className="dropdown-item" href="#">
                    EUR
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    AED
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    RUBL
                  </a>
                </li>
              </ul> */}
            </li>
          </ul>
          <ul className="navbar-nav">
            {/* <li>
              <a href="#" className="nav-link">
                <i className="fa fa-comment" /> Live chat
              </a>
            </li> */}
            <li>
              <a href={"tel:" + data.telephone} className="nav-link">
                <i className="fa fa-phone" /> Appelez nous
              </a>
            </li>
          </ul>
          {/* list-inline //  */}
        </div>
        {/* navbar-collapse .// */}
      </div>
      {/* container //  */}
    </nav>
  );
}

export default TopNavbar;

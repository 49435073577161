import React from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function CodeCommande(props) {
  const handleCopyCOde = () => {
    navigator.clipboard.writeText(props.match.params.id);
    toast("Code commande copié");
  };
  return (
    <div className="container mt-2 mb-2">
      <ToastContainer></ToastContainer>
      <div className="d-flex justify-content-center">
        <div className="column">
          <img
            src="images/congrat.svg"
            alt="empty commnade"
            style={{ width: "300px", height: "300px" }}
          />
          <p className="text-center font-weight-bold">Votre code commande</p>
          <h1 className="text-center font-weight-bold" onClick={handleCopyCOde}>
            {props.match.params.id} <i className="far fa-clone"></i>
          </h1>
          <small>
            Ce code sera généré une seule fois, vous devez copier ce code pour
            suivre votre commande
          </small>
          <br></br>
          <br></br>
          <Link
            to={"/trackorder/" + props.match.params.id}
            className="btn btn-primary btn-block"
          >
            Suivie commande
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CodeCommande;

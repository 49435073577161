import { BrowserRouter as Router, withRouter } from "react-router-dom";
import Shop from "./layouts/Shop";
import BodyShop from "./containers/BodyShop";
import SignIn from "./components/pages/SignIn";
import SignUp from "./components/pages/SignUp";
import Profile from "./components/pages/Profile";
import Cart from "./components/pages/Cart";
import ProductGrid from "./components/pages/ProductGrid";
import ProductList from "./components/pages/ProductList";
import Favoris from "./components/pages/Favoris";
import DetailProduct from "./components/pages/DetailProduct";
import Categories from "./components/pages/Categories";
import Orders from "./components/pages/Orders";
import ContactUs from "./components/pages/ContactUs";
import FilterGamme from "./components/pages/FilterGamme";
import FilterFamille from "./components/pages/FilterFamille";
import FilterSearch from "./components/pages/FilterSearch";
import TrackCommand from "./components/pages/TrackCommand";
import Statement from "./components/pages/Statement";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import ShopList from "./layouts/ShopList";
import FilterSousFamille from "./components/pages/FilterSousFamille";
import AppRoute from "./routes/AppRoute";
import CodeCommande from "./components/pages/CodeCommande";
import TrackCommandeGuest from "./components/pages/TrackCommandeGuest";
import { useEffect, useState } from "react";
import { getEntrepriseDetails } from "./services/EntrepriseApi";

/**
 * main app
 * routes
 * @returns
 */
function App() {
  const [connected, setconnected] = useState(null);
  useEffect(() => {
    getEntrepriseDetails().then((res) => {
      setconnected(res.data[0].nomashop_login);
      //setconnected("1");
    });
  }, []);
  return (
    <Router>
      {connected == 0 && (
        <>
          <AppRoute path="/" exact layout={Shop} component={BodyShop} />
          <PublicRoute path="/login" layout={Shop} component={SignIn} />
          <PublicRoute path="/register" layout={Shop} component={SignUp} />
          <PrivateRoute path="/account" layout={Shop} component={Profile} />
          <AppRoute path="/cart" layout={Shop} component={Cart} />
          <AppRoute path="/contact" layout={Shop} component={ContactUs} />
          <AppRoute path="/productgrid" layout={Shop} component={ProductGrid} />
          <AppRoute path="/productlist" layout={Shop} component={ProductList} />
          <AppRoute path="/favoris" layout={Shop} component={Favoris} />
          <AppRoute path="/detail/:id" layout={Shop} component={DetailProduct} />
          <AppRoute path="/category" layout={Shop} component={Categories} />
          <PrivateRoute path="/commande" layout={Shop} component={Orders} />
          <AppRoute path="/filtergamme/:id" layout={ShopList} component={withRouter(FilterGamme)} />
          <AppRoute path="/filterfamille/:id" layout={ShopList} component={FilterFamille} />
          <AppRoute path="/filtersousfamille/:id" layout={ShopList} component={FilterSousFamille} />
          <AppRoute path="/search/:id" layout={ShopList} component={FilterSearch} />
          <AppRoute path="/trackorder/:id" layout={Shop} component={TrackCommand} />
          <AppRoute path="/searchorder" layout={Shop} component={TrackCommandeGuest} />
          <PrivateRoute path="/statement" layout={Shop} component={Statement} />
          <AppRoute path="/codecommande/:id" layout={Shop} component={CodeCommande} />
        </>
      )}
      {connected == 1 && (
        <>
          <PrivateRoute path="/" exact layout={Shop} component={BodyShop} />
          <PublicRoute path="/login" layout={Shop} component={SignIn} />
          <PublicRoute path="/register" layout={Shop} component={SignUp} />
          <PrivateRoute path="/account" layout={Shop} component={Profile} />
          <PrivateRoute path="/cart" layout={Shop} component={Cart} />
          <PrivateRoute path="/contact" layout={Shop} component={ContactUs} />
          <PrivateRoute path="/productgrid" layout={Shop} component={ProductGrid} />
          <PrivateRoute path="/productlist" layout={Shop} component={ProductList} />
          <PrivateRoute path="/favoris" layout={Shop} component={Favoris} />
          <PrivateRoute path="/detail/:id" layout={Shop} component={DetailProduct} />
          <PrivateRoute path="/category" layout={Shop} component={Categories} />
          <PrivateRoute path="/commande" layout={Shop} component={Orders} />
          <PrivateRoute path="/filtergamme/:id" layout={ShopList} component={withRouter(FilterGamme)} />
          <PrivateRoute path="/filterfamille/:id" layout={ShopList} component={FilterFamille} />
          <PrivateRoute path="/filtersousfamille/:id" layout={ShopList} component={FilterSousFamille} />
          <PrivateRoute path="/search/:id" layout={ShopList} component={FilterSearch} />
          <PrivateRoute path="/trackorder/:id" layout={Shop} component={TrackCommand} />
          <PrivateRoute path="/searchorder" layout={Shop} component={TrackCommandeGuest} />
          <PrivateRoute path="/statement" layout={Shop} component={Statement} />
          <PrivateRoute path="/codecommande/:id" layout={Shop} component={CodeCommande} />
        </>
      )}
    </Router>
  );
}

export default App;

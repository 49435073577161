import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actionCreators from "../../actions/index";
import { filterByWord, priceBetween } from "../../Utils/SortObject";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isLogin } from "../../Utils/SessionUtils";

function Favoris(props) {
  const [search, setsearch] = useState("");
  const [currentData, setcurrentData] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [dataPerPages, setdataPerPages] = useState(3);
  const [pageNumbers, setpageNumbers] = useState([]);
  const [data, setdata] = useState([]);

  const [min, setmin] = useState("");
  const [max, setmax] = useState("");

  useEffect(() => {
    //setinputList(props.products.favoris);
    paginateData(currentPage, props.products.favoris);
    setdata(props.products.favoris);
  }, []);

  const paginateData = (currentPage, allDataToPaginate) => {
    // calculate first index 1 * 9 = 9
    const indexOfLastPost = currentPage * dataPerPages;
    // calculate last index = 9 - 9 = 0
    const indexOfFirstPost = indexOfLastPost - dataPerPages;
    //ajouter list product to currentData
    // currentData = products.slice(0,9)
    setcurrentData(allDataToPaginate.slice(indexOfFirstPost, indexOfLastPost));

    // math.ceil = returns the smallest integer greater than or equal to the given number
    const pageNumbersCount = [];
    // math.ceil = returns the smallest integer greater than or equal to the given number
    // console.log(Math.ceil(2.95))  ===>  2
    for (let i = 1; i <= Math.ceil(allDataToPaginate.length / dataPerPages); i++) {
      // count page number for pagination [1,2,3,4,5,6,7,8,9]
      pageNumbersCount.push(i);
    }
    // add list of pagination number
    setpageNumbers(pageNumbersCount);
  };

  /**
   * onclick in page number
   * @param {event} pageNumber
   */
  const paginate = (pageNumber) => {
    //update pagination
    paginateData(pageNumber, data);
    //update current page of pagination
    setcurrentPage(pageNumber);
  };
  /**
   * add product id to redux
   * @param {integer} id
   */
  const handleClick = (item) => {
    props.addToCart(item);
    toast(" 🛒 Produit ajouté");
  };

  /**
   * delete product id from redux
   * @param {integer} id
   */
  const handleClickDeleteFavoris = (id) => {
    //delete id from redux
    props.DeleteFavoris(id);
    //delte id from current data
    let newFilter = data.filter((el) => el.id != id);
    setcurrentData(newFilter);

    paginateData(currentPage, newFilter);
    setdata(newFilter);
  };

  //next page onclick
  const nextPage = () => {
    //update pagination
    paginateData(currentPage + 1, data);
    //update current page of pagination
    setcurrentPage(currentPage + 1);
  };
  // previous page onclick
  const previousPage = () => {
    //update pagination
    paginateData(currentPage + 1, data);
    //update current page of pagination
    setcurrentPage(currentPage - 1);
  };
  /**
   * handle search event
   * @param {event} e
   */
  const handleSearch = (e) => {
    setsearch(e.target.value);

    // if search not empty
    if (e.target.value) {
      // filter by word
      let newList = filterByWord(currentData, e.target.value);
      //setdata(newList);
      paginateData(currentPage, newList);
    } else {
      paginateData(currentPage, props.products.favoris);
      //setdata(props.products.favoris);
    }
  };

  /**
   * filter product by price
   */
  const handleConfirmPrice = () => {
    // filter by price
    const priceFilter = priceBetween(data, min, max);
    //setdata(priceFilter);
    paginateData(currentPage, priceFilter);
  };

  return (
    <div>
      <section className="section-content padding-y bg">
        <div className="container">
          <div className="row">
            <main className="col-md-9">
              {currentData.map((el) => (
                <article key={el.id} className="card card-product-list">
                  <div className="row no-gutters">
                    <aside className="col-md-3">
                      <a href="#" className="img-wrap">
                        <span className="badge badge-danger"> Favoris </span>
                        <img src={el.image == null || el.image == "" ? "/images/product/product.png" : el.image} />
                      </a>
                    </aside>
                    {/* col.// */}
                    <div className="col-md-6">
                      <div className="info-main">
                        <Link to={"/detail/" + el.code} href="#" className="h5 title">
                          {el.libelle}
                        </Link>
                        {/* <div className="rating-wrap mb-3">
                          <ul className="rating-stars">
                            <li style={{ width: "80%" }} className="stars-active">
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                            </li>
                            <li>
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                            </li>
                          </ul>
                          <div className="label-rating">7/10</div>
                        </div> */}
                        {/* rating-wrap.// */}
                        {/* <p>
                          Take it as demo specs, ipsum dolor sit amet, consectetuer adipiscing elit, Lorem ipsum dolor
                          sit amet, consectetuer adipiscing elit, Ut wisi enim ad minim veniam
                        </p> */}
                      </div>
                      {/* info-main.// */}
                    </div>
                    {/* col.// */}
                    <aside className="col-sm-3">
                      <div className="info-aside">
                        <div className="price-wrap">
                          <a href="#" className="btn-link float-right">
                            <i className="fa fa-heart"></i>
                          </a>
                          <span className="price h5">
                            {isLogin() ? el.prix_vente_nomashop_2 : el.prix_vente_nomashop_1}
                            DT
                          </span>
                          <del className="price-old"> {el.prix_ht} DT</del>
                        </div>
                        {/* info-price-detail // */}

                        <p className="text-success">{el.colisage_nomashop} PCB</p>
                        <br />
                        <p>
                          <Link to={"/favoris"} className="btn btn-primary btn-block" onClick={() => handleClick(el)}>
                            Ajouter au panier
                          </Link>
                          <Link
                            to={"/favoris"}
                            className="btn btn-light btn-block"
                            onClick={() => handleClickDeleteFavoris(el.id)}
                          >
                            <i className="fa fa-trash" />
                            <span className="text">Supprimer</span>
                          </Link>
                        </p>
                      </div>
                      <ToastContainer></ToastContainer>
                      {/* info-aside.// */}
                    </aside>
                    {/* col.// */}
                  </div>
                  {/* row.// */}
                </article>
              ))}
              <nav className="mt-4" aria-label="Page navigation sample">
                <ul className="pagination pagination-responsive pagination-sm">
                  <li className={currentPage == 1 ? "page-item disabled" : "page-item"}>
                    <Link to="/favoris" onClick={previousPage} className="page-link">
                      Précédent
                    </Link>
                  </li>
                  {pageNumbers.map((el, index) => (
                    <li key={index} className={el == currentPage ? "page-item active" : "page-item"}>
                      <Link to="/favoris" onClick={() => paginate(el)} className="page-link">
                        {el}
                      </Link>
                    </li>
                  ))}
                  <li className="page-item">
                    <Link to="/favoris" onClick={nextPage} className="page-link" href="#">
                      Suivant
                    </Link>
                  </li>
                </ul>
              </nav>
            </main>
            {/* col.// */}
            <aside className="col-md-3">
              {/* COMPONENTS SIDEBAR */}
              <output>
                <div className="card">
                  <article className="filter-group">
                    <header className="card-header">
                      <a href="#" data-toggle="collapse" data-target="#collapse_aside1">
                        <i className="icon-control fa fa-chevron-down" />
                        <h6 className="title">Recherche </h6>
                      </a>
                    </header>
                    <div className="filter-content collapse show" id="collapse_aside1">
                      <div className="card-body">
                        <form className="mb-3">
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Rechercher ..."
                              value={search || ""}
                              onChange={(e) => handleSearch(e)}
                            />
                            <div className="input-group-append">
                              <button className="btn btn-primary" type="button">
                                <i className="fa fa-search" />
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </article>
                  <article className="filter-group">
                    <header className="card-header">
                      <a href="#" data-toggle="collapse" data-target="#collapse_aside2">
                        <i className="icon-control fa fa-chevron-down" />
                        <h6 className="title">Prix </h6>
                      </a>
                    </header>
                    <div className="filter-content collapse show" id="collapse_aside2">
                      <div className="card-body">
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label>Min</label>
                            <input
                              className="form-control"
                              placeholder="0 DT"
                              type="number"
                              value={min}
                              onChange={(e) => setmin(e.target.value)}
                            />
                          </div>
                          <div className="form-group text-right col-md-6">
                            <label>Max</label>
                            <input
                              className="form-control"
                              placeholder="1,0000 DT"
                              type="number"
                              value={max}
                              onChange={(e) => setmax(e.target.value)}
                            />
                          </div>
                        </div>
                        <button className="btn btn-block btn-primary" onClick={handleConfirmPrice}>
                          Appliquer
                        </button>
                      </div>
                    </div>
                  </article>
                  <article className="filter-group">
                    <header className="card-header">
                      <a href="#" data-toggle="collapse" data-target="#collapse_aside4">
                        <i className="icon-control fa fa-chevron-down" />
                        <h6 className="title">Autre filtres </h6>
                      </a>
                    </header>
                    <div className="filter-content collapse" id="collapse_aside4">
                      <div className="card-body">
                        <label className="custom-control custom-checkbox">
                          <input type="checkbox" defaultChecked className="custom-control-input" />
                          <div className="custom-control-label">Best</div>
                        </label>
                        <label className="custom-control custom-checkbox">
                          <input type="checkbox" defaultChecked className="custom-control-input" />
                          <div className="custom-control-label">Good </div>
                        </label>
                        <label className="custom-control custom-checkbox">
                          <input type="checkbox" defaultChecked className="custom-control-input" />
                          <div className="custom-control-label">Normal</div>
                        </label>
                        <label className="custom-control custom-checkbox">
                          <input type="checkbox" defaultChecked className="custom-control-input" />
                          <div className="custom-control-label">Not good</div>
                        </label>
                      </div>
                    </div>
                  </article>
                </div>
              </output>
            </aside>
          </div>
          <br /> <br /> <br /> <br />
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = (state) => {
  return { products: state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (item) => {
      dispatch(actionCreators.addToCart(item));
    },
    DeleteCart: (id) => {
      dispatch(actionCreators.DeleteCart(id));
    },
    DeleteFavoris: (id) => {
      dispatch(actionCreators.DeleteFavoris(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Favoris);

import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import CustomSyncLoader from "../../others/CustomSyncLoader";
import ErrorAlert from "../../others/ErrorAlert";
import { getAllFamilles, getProductByFamilles } from "../../services/FamilleApi";
import { isLogin } from "../../Utils/SessionUtils";

function Category() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);

  useEffect(() => {
    setLoading(true);
    var array = [];
    getAllFamilles()
      .then((res) => {
        res.data.forEach((element) => {
          array.push(element.code);
        });
        const rndInt = Math.floor(Math.random() * array.length) + 1;
        // settitle(array[rndInt]);
        // for (let i = 0; i < array.length; i++) {
        getProductByFamilles(array[rndInt]).then((o) => {
          console.log(o.data);
          if (o.data.length > 8) {
            setdata(o.data.slice(0, 8));
          } else {
            setdata(o.data);
          }
          setLoading(false);
        });
      })
      .catch((error) => setError(error));
  }, []);

  return (
    <>
      {loading && (
        <div className="container mt-5">
          <div className="d-flex align-items-center justify-content-center h-100">
            <CustomSyncLoader></CustomSyncLoader>
          </div>
        </div>
      )}
      {!loading && error && <ErrorAlert msg={`Erreur: ${error}`}></ErrorAlert>}
      {!loading && !error && data.length > 0 && (
        <div className="container">
          <section className="padding-bottom">
            {/* <header className="section-heading heading-line">
              <h4 className="title-section text-uppercase">{title}</h4>
            </header> */}
            <div className="card card-home-category">
              <div className="row no-gutters">
                <div className="col-md-3">
                  <div className="home-category-banner bg-light p-4">
                    <h5 className="title">{data[0].libelle}</h5>
                    <p>{isLogin() ? data[0].prix_vente_nomashop_2 : data[0].prix_vente_nomashop_1} DT</p>
                    <Link to={"/detail/" + data[0].code} className="btn btn-outline-primary rounded-pill">
                      Voir plus
                    </Link>
                    <img
                      src={data[0].image ? data[0].image : "/images/product/product.png"}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "/images/product/product.png";
                      }}
                      className="img-bg"
                      style={{ width: "100px", height: "", marginLeft: "10px" }}
                    />
                  </div>
                </div>{" "}
                {/* col.// */}
                <div className="col-md-9">
                  <ul className="row no-gutters bordered-cols">
                    {data.map((el) => (
                      <li key={el.id} className="col-6 col-lg-3 col-md-4">
                        <Link to={"/detail/" + el.code} className="item">
                          <div className="card-body">
                            <h6 className="title">{el.libelle}</h6>
                            <img
                              className="img-sm float-right"
                              src={el.image ? el.image : "/images/product/product.png"}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/images/product/product.png";
                              }}
                            />
                            <p className="text-muted">
                              {/* <i className="fa fa-map-marker-alt" /> */}
                              {isLogin() ? el.prix_vente_nomashop_2 : el.prix_vente_nomashop_1} DT
                            </p>
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>{" "}
                {/* col.// */}
              </div>{" "}
              {/* row.// */}
            </div>{" "}
            {/* card.// */}
          </section>
        </div>
      )}
    </>
  );
}

export default Category;

import React from "react";
import { Redirect, Route } from "react-router-dom";
import { isLogin } from "../Utils/SessionUtils";

/**
 * private Router navigation
 * @param {Object} param0
 * @returns
 */
const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isLogin() ? (
        <Layout>
          <Component {...props}></Component>
        </Layout>
      ) : (
        <Redirect to="/login" />
      )
    }
  ></Route>
);

export default PrivateRoute;

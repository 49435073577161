import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
require("dotenv").config();


const api_url = process.env.REACT_APP_API_ENDPOINT;

/**
 * get statement by code
 */
export async function getStatementByCode() {
    const user = JSON.parse(localStorage.getItem("user"));
  try {
    const response = await axios.get(api_url + "mouvement_clients/getMouvementsByCode/"+user.code, {
      withCredentials: true,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getCommandeByCode } from "../../services/CommandeApi";

function TrackCommand(props) {
  const [data, setdata] = useState([]);
  const [tracked, settracked] = useState(false);

  useEffect(() => {
    getCommandeByCode(props.match.params.id).then((res) => {
      if (res.data.length === 0) {
        settracked(true);
      } else {
        setdata(res.data[0]);
      }
    });
  }, []);
  return (
    <div className="container mt-2 mb-2">
      {tracked && (
        <div className="d-flex justify-content-center">
          <div className="column">
            <img
              src="images/empty.svg"
              alt="empty commnade"
              style={{ width: "300px", height: "300px" }}
            />
            <p className="text-center font-weight-bold">
              Commande en cours de traitement
            </p>
          </div>
        </div>
      )}
      {!tracked && (
        <article className="card">
          <header className="card-header"> Mes commandes / suivi </header>
          <div className="card-body">
            <h6>Code Commande: {data.num_cmd}</h6>
            <article className="card">
              <div className="card-body row no-gutters">
                <div className="col">
                  <strong>Délai de livraison estimé:</strong> <br />
                  {data.date_livraison}
                </div>
                <div className="col">
                  <strong>Adresse de livraison:</strong> <br />
                  <i className="fa fa-home" /> {data.adresse_livraison}
                </div>
                <div className="col">
                  <strong>Status:</strong> <br /> {data.status}
                </div>
                <div className="col">
                  <strong>Suivie #:</strong> <br /> {data.code}
                </div>
              </div>
            </article>
            <div className="tracking-wrap">
              <div
                className={data.status == "Non traité" ? "step active" : "step"}
              >
                <span className="icon">
                  <i className="fa fa-check" />
                </span>
                <span className="text">Non traité</span>
              </div>
              {/* step.// */}
              <div
                className={
                  data.status == "Preparation" ? "step active" : "step"
                }
              >
                <span className="icon">
                  <i className="fas fa-luggage-cart" />
                </span>
                <span className="text"> Préparation</span>
              </div>
              {/* step.// */}
              <div className={data.status == "Chargé" ? "step active" : "step"}>
                <span className="icon">
                  <i className="fa fa-box" />
                </span>
                <span className="text"> Chargé </span>
              </div>
              {/* step.// */}
              <div className={data.status == "Sortie" ? "step active" : "step"}>
                <span className="icon">
                  <i className="fa fa-truck" />
                </span>
                <span className="text">Sortie</span>
              </div>
              <div className={data.status == "Livré" ? "step active" : "step"}>
                <span className="icon">
                  <i className="fa fa-check" />
                </span>
                <span className="text">Livré</span>
              </div>
              <div className={data.status == "Annulé" ? "step active" : "step"}>
                <span className="icon">
                  <i className="fas fa-window-close" />
                </span>
                <span className="text">Annulé</span>
              </div>
              {/* step.// */}
            </div>
            <hr />
            <p>
              <strong>Note: </strong>
            </p>
            <Link to="/commande" className="btn btn-light">
              <i className="fa fa-chevron-left" /> Retour aux commandes
            </Link>
          </div>
          {/* card-body.// */}
        </article>
      )}
    </div>
  );
}

export default TrackCommand;

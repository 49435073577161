import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
require("dotenv").config();

const api_url = process.env.REACT_APP_API_ENDPOINT;

/**
 * get all promotions
 */
export async function getAllPromotions() {
  try {
    const response = await axios.get(api_url + "promotions", {
      withCredentials: true,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * get all gratuites conditions
 */
export async function getPromotionProduit() {
  try {
    const response = await axios.get(api_url + "promotions/produits", {
      withCredentials: true,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

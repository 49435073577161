/**
 * get gamme list
 * @param {Object} arrayObject 
 * @returns 
 */
export const getFilterGamme = (arrayObject) => {
  let uniqueList = [];
  arrayObject.forEach((element) => uniqueList.push(element.gamme.libelle));
  return [...new Set(uniqueList)];
};

/**
 * get famille list
 * @param {Object} arrayObject 
 * @returns 
 */
export const getFilterFamille = (arrayObject) => {
  let uniqueList = [];
  arrayObject.forEach((element) => uniqueList.push(element.famille.libelle));
  return [...new Set(uniqueList)];
};

/**
 * get sous famille list
 * @param {Object} arrayObject 
 * @returns 
 */
export const getFilterSousFamille = (arrayObject) => {
  let uniqueList = [];
  arrayObject.forEach((element) => uniqueList.push(element.sousfamille.libelle));
  return [...new Set(uniqueList)];
};

export function idExistInFavoris(arrayObject, value) {
  return arrayObject.some(function(el) {
    return el.id === value;
  }); 
}

import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from "../../actions/index";
import { idExistInFavoris } from "../../Utils/ObjectList";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isLogin } from "../../Utils/SessionUtils";
function ProductCard(props) {
  /**
   * add product to cart
   * @param {Integer} id
   */
  const handleClick = (item) => {
    props.addToCart(item);
    toast(" 🛒 Produit ajouté");
  };

  /**
   * add product to favoris
   * @param {Integer} id
   */
  const handleClickFavoris = (item) => {
    props.addToFavoris(item);
  };

  return (
    <>
      {props.itemsList.map((el) => (
        <div key={el.id} className="col-md-3">
          <figure className="card card-product-grid">
            <div className="img-wrap">
              <span className="badge badge-danger"> Nouveau </span>
              <img
                loading="lazy"
                src={el.image ? el.image : "/images/product/product.png"}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "/images/product/product.png";
                }}
              />
              {/* <span className="topbar">
                    <span className="badge badge-success"> NOUVEAU </span>
                  </span> */}
              <span className="topbar">
                <Link to="#" className="float-right" onClick={() => handleClickFavoris(el)}>
                  {idExistInFavoris(props.products.favoris, el.id) ? (
                    <i className="fa fa-heart" style={{ color: "#1650E0" }} />
                  ) : (
                    <i className="fa fa-heart" />
                  )}
                </Link>
              </span>
              <Link to={"/detail/" + el.code} className="btn-overlay">
                <i className="fa fa-search-plus" /> Aperçu rapide
              </Link>
              {/* <a className="btn-overlay" href="#">
                    <i className="fa fa-search-plus" /> Aperçu rapide
                  </a> */}
            </div>
            <figcaption className="info-wrap text-center">
              <small className="text-uppercase font-weight-bolder text-warning">{el.gamme.libelle}</small>
              <a href="#" className="title">
                {el.libelle}
              </a>
            </figcaption>
            <div className="bottom-wrap">
              <Link to="#" className="btn btn-sm btn-outline-primary float-right mt-2" onClick={() => handleClick(el)}>
                Ajouter au panier <i className="fa fa-shopping-cart" />
              </Link>
              <div className="price-wrap">
                <span className="price h5">{isLogin() ? el.prix_vente_nomashop_2 : el.prix_vente_nomashop_1} DT</span>
                <br />
                <small className="text-success">{el.colisage_nomashop} PCB</small>
              </div>
              {/* price-wrap.// */}
            </div>
            {/* bottom-wrap.// */}
          </figure>
          {/* card // */}
        </div>
      ))}
      <ToastContainer></ToastContainer>
    </>
  );
}
const mapStateToProps = (state) => {
  return { products: state };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (item) => {
      dispatch(actionCreators.addToCart(item));
    },
    addToFavoris: (item) => {
      dispatch(actionCreators.addToFavoris(item));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);

import React, { useEffect, useState, useRef } from "react";
import { getStatementByCode } from "../../services/StatementApi";
import ErrorAlert from "./../../others/ErrorAlert";
import CustomSyncLoader from "./../../others/CustomSyncLoader";
import { Link } from "react-router-dom";
import TableStatement from "../Table/TableStatement";
import { useReactToPrint } from "react-to-print";

function Statement() {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);

  useEffect(() => {
    setLoading(true);
    getStatementByCode()
      .then((res) => {
        setdata(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className="container mt-2 mb-2">
      {loading && (
        <div className="container mt-5">
          <div className="d-flex align-items-center justify-content-center h-100">
            <CustomSyncLoader></CustomSyncLoader>
          </div>
        </div>
      )}
      {!loading && error && (
        <div>
          <ErrorAlert msg={`Erreur: ${error}`}></ErrorAlert>
        </div>
      )}
      {!loading && !error && data && (
        // <TableStatement statementItems={data}></TableStatement>
        <div className="bg-gray-200 p-6">
          <Link
            to="#"
            className="btn btn-sm btn-outline-primary float-right mt-2"
            onClick={handlePrint}
          >
            Imprimer <i className="fa fa-print" />
            
          </Link>
          <br></br>
          <br></br>
          <TableStatement statementItems={data} ref={componentRef} />
        </div>
      )}
    </div>
  );
}

export default Statement;

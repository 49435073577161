import React from "react";
import Banner from "../components/Banners/Banner";
import Banner1 from "../components/Banners/Banner1";
import Banner2 from "../components/Banners/Banner2";
import Category from "../components/Categories/Category";
import Brands from "../components/pages/Brands";
import Features from "../components/pages/Features";
import Gratuite from "../components/pages/Gratuite";
import NewArrived from "../components/pages/NewArrived";
import PopularProduct from "../components/pages/PopularProduct";
import Promotion from "../components/pages/Promotion";
import Recommended from "../components/pages/Recommended";
import Menu from "../components/shared/Menu";
import Menu1 from "../components/shared/Menu1";

/**
 * body shop components
 * @returns
 */
function BodyShop() {
  return (
    <div>
      <Menu></Menu>
      <Features></Features>
      <Menu1></Menu1>
      <Category></Category>
      {/* <Banner1></Banner1> */}
      <Promotion></Promotion>
      <Gratuite></Gratuite>
      <NewArrived></NewArrived>
      <Banner2></Banner2>
      {/* <PopularProduct></PopularProduct>
      
      <Recommended></Recommended>  */}
    </div>
  );
}

export default BodyShop;

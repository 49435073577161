import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getAllGammes } from "../../services/GammeApi";
import ErrorAlert from "./../../others/ErrorAlert";
import CustomSyncLoader from "./../../others/CustomSyncLoader";

function Brands() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);

  useEffect(() => {
    setLoading(true);
    getAllGammes()
      .then((res) => {
        setdata(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  return (
    <section className="section-name bg padding-y-sm">
      <div className="container">
        <header className="section-heading">
          <h3 className="section-title">Nos marques</h3>
        </header>
        {loading && (
          <div className="container mt-5">
            <div className="d-flex align-items-center justify-content-center h-100">
              <CustomSyncLoader></CustomSyncLoader>
            </div>
          </div>
        )}
        {!loading && error && (
          <div>
            <ErrorAlert msg={`Erreur: ${error}`}></ErrorAlert>
          </div>
        )}
        {!loading && !error && data && (
          <div className="row d-flex justify-content-center">
            {data.slice(0, 6).map((item, index) => (
              <div key={index} className="col-md-2 col-6">
                <figure className="box item-logo">
                  <div>
                    <Link to={"/filterfamille/" + item.code}>
                      <img
                        src={
                          item.image == null || item.image == ""
                            ? "/images/product/brand.png"
                            : item.image
                        }
                      />
                    </Link>
                  </div>
                  <div>
                    <small className="text-uppercase font-weight-bolder text-warning">
                      {item.libelle}
                    </small>
                  </div>
                  {/* <figcaption className="border-top pt-2">
                    {item.PRODUIT.length} Products
                  </figcaption> */}
                </figure>
                {/* item-logo.// */}
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
}

export default Brands;

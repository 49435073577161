import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from "../../actions/index";
import { idExistInFavoris } from "../../Utils/ObjectList";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isLogin } from "../../Utils/SessionUtils";
function ProductCardFull(props) {
  /**
   * add product to cart
   * @param {Integer} id
   */
  const handleClick = (item) => {
    props.addToCart(item);
    toast(" 🛒 Produit ajouté");
  };

  /**
   * add product to favoris
   * @param {Integer} id
   */
  const handleClickFavoris = (item) => {
    props.addToFavoris(item);
  };

  return (
    <>
      {props.itemsList.map((el) => (
        <div key={el.id} className="col-md-4">
          <figure className="card card-product-grid">
            <div className="img-wrap">
              {/* <span className="badge badge-danger"> Nouveau</span> */}
              <span className="topbar">
                <Link to="#" className="float-right" onClick={() => handleClickFavoris(el)}>
                  {idExistInFavoris(props.products.favoris, el.id) ? (
                    <i className="fa fa-heart" style={{ color: "#1650E0" }} />
                  ) : (
                    <i className="fa fa-heart" />
                  )}
                </Link>
              </span>
              <img
                loading="lazy"
                src={el.image ? el.image : "/images/product/product.png"}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "/images/product/product.png";
                }}
              />
              <Link to={"/detail/" + el.code} className="btn-overlay">
                <i className="fa fa-search-plus" /> Aperçu rapide
              </Link>
            </div>
            {/* img-wrap.// */}
            <figcaption className="info-wrap">
              <div className="fix-height">
                <Link to={"/detail/" + el.code} className="title">
                  {el.libelle}
                </Link>
                <div className="price-wrap mt-2">
                  <span className="price">{isLogin() ? el.prix_vente_nomashop_2 : el.prix_vente_nomashop_1} DT</span>
                  <del className="price-old">{el.prix_ht} DT</del>
                </div>
                {/* price-wrap.// */}
              </div>
              <Link to="#" className="btn btn-block btn-primary" onClick={() => handleClick(el)}>
                Ajouter au panier
              </Link>
            </figcaption>
          </figure>
        </div>
      ))}
      <ToastContainer></ToastContainer>
    </>
  );
}
const mapStateToProps = (state) => {
  return { products: state };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (item) => {
      dispatch(actionCreators.addToCart(item));
    },
    addToFavoris: (item) => {
      dispatch(actionCreators.addToFavoris(item));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardFull);

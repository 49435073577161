import axios from "axios";
require("dotenv").config();

const api_url = process.env.REACT_APP_API_ENDPOINT;

/**
 * get all products
 */
export async function getProducts() {
  try {
    const response = await axios.get(api_url + "productList", {
      withCredentials: true,
    });
    //var data_filter = response.data.filter(el => el.vendable_web == "1")
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * get TOp products
 */
export async function getTopProducts() {
  try {
    const response = await axios.get(api_url + "produits/getTop10NewArticles", {
      withCredentials: true,
    });
    //var data_filter = response.data.filter(el => el.vendable_web == "1")
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * get product by id
 */
export async function getProductByCode(id) {
  try {
    const response = await axios.get(api_url + "produits/code/" + id, {
      withCredentials: true,
    });
    //var data_filter = response.data.filter(el => el.vendable_web == "1")
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * search product by word
 */
export async function getProductByText(id) {
  try {
    const response = await axios.get(
      api_url + "produits/searchArticles/" + id,
      {
        withCredentials: true,
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

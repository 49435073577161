/**
 * calculate discount 
 * @param {Integer} total
 * @param {Integer} remise
 */
export const calculateRemise = (total, remise) => {
  var numVal1 = Number(total);
  var numVal2 = (Number(remise)*numVal1) / 100;
  var totalValue = numVal1 - numVal2;
  return totalValue.toFixed(3);
};

/**
 * calculate discount 
 * @param {Integer} total
 * @param {Integer} remise
 */
export const calculateMontantRemise = (total, remise) => {
  var numVal2 = (Number(remise)/100) * Number(total);
  return numVal2.toFixed(3);
};

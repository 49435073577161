import axios from "axios";
require("dotenv").config();

const api_url = process.env.REACT_APP_API_ENDPOINT;
/**
 * login users
 * @param {*} email
 * @param {*} password
 */
export async function userLogin(email, password) {
  axios.defaults.withCredentials = true;
  const form = new FormData();
  form.append("email", email);
  form.append("password", password);

  // const data = { email: email, password: password };
  try {
    const response = await axios.post(api_url + "loginClient", form, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      withCredentials: true,
      credentials: "include",
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

import React from "react";
import { Link } from "react-router-dom";

function SignUp() {
  return (
    <div>
      {/* section-header.// */}
      <section className="section-content padding-y bg">
        <div className="container">
          <div className="row">
            <aside className="col-md-3"></aside>
            <aside className="col-md-6">
              {/* ============================ COMPONENT SIGNUP ================================= */}
              <div className="card">
                <article className="card-body">
                  <header className="mb-4">
                    <h4 className="card-title">S'inscrire</h4>
                  </header>
                  <form>
                    <div className="form-row">
                      <div className="col form-group">
                        <label>Nom</label>
                        <input
                          type="text"
                          className="form-control"
                          
                        />
                      </div>
                      {/* form-group end.// */}
                      <div className="col form-group">
                        <label>Prénom</label>
                        <input
                          type="text"
                          className="form-control"
                          
                        />
                      </div>
                      {/* form-group end.// */}
                    </div>
                    {/* form-row end.// */}
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        
                      />
                      <small className="form-text text-muted">
                        Nous ne partagerons jamais votre e-mail avec qui que ce soit.
                      </small>
                    </div>
                    {/* form-group end.// */}
                    <div className="form-group">
                      <label className="custom-control custom-radio custom-control-inline">
                        <input
                          className="custom-control-input"
                          defaultChecked
                          type="radio"
                          name="gender"
                          defaultValue="option1"
                        />
                        <span className="custom-control-label"> Male </span>
                      </label>
                      <label className="custom-control custom-radio custom-control-inline">
                        <input
                          className="custom-control-input"
                          type="radio"
                          name="gender"
                          defaultValue="option2"
                        />
                        <span className="custom-control-label"> Female </span>
                      </label>
                    </div>
                    {/* form-group end.// */}
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label>City</label>
                        <input type="text" className="form-control" />
                      </div>
                      {/* form-group end.// */}
                      <div className="form-group col-md-6">
                        <label>Country</label>
                        <select id="inputState" className="form-control">
                          <option> Choisir...</option>
                          <option>Tunisie</option>
                          <option>Algérie</option>
                          <option>Libye</option>
                          <option>Maroc</option>
                        </select>
                      </div>
                      {/* form-group end.// */}
                    </div>
                    {/* form-row.// */}
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label>Créer un mot de passe</label>
                        <input className="form-control" type="password" />
                      </div>
                      {/* form-group end.// */}
                      <div className="form-group col-md-6">
                        <label>Répéter le mot de passe</label>
                        <input className="form-control" type="password" />
                      </div>
                      {/* form-group end.// */}
                    </div>
                    <div className="form-group mt-3">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        S'inscrire
                      </button>
                    </div>
                    {/* form-group// */}
                    <p className="text-muted">
                      En cliquant sur le bouton «S'inscrire», vous confirmez que vous acceptez 
                      nos conditions d'utilisation et notre politique de confidentialité.
                    </p>
                  </form>
                  <hr />
                  <p className="text-center">
                    avez-vous un compte? <Link to="/login">
                    S'identifier
                  </Link>
                  </p>
                </article>
                {/* card-body end .// */}
              </div>
              {/* card.// */}
              {/* ============================ COMPONENT SIGNUP  END .// ================================= */}
            </aside>
            <aside className="col-md-3"></aside>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SignUp;

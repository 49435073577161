import React, { useEffect, useState } from "react";
import { getAllGratuites, getAllGratuitesConditions } from "../../services/GratuitesApi";
import ErrorAlert from "./../../others/ErrorAlert";
import CustomSyncLoader from "./../../others/CustomSyncLoader";
import { getProductByCode } from "../../services/ProductApi";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from "../../actions/index";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Gratuite(props) {
  const [freeProduct, setfreeProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [code, setcode] = useState("");
  const [conditions, setconditions] = useState([]);
  const [dataCondition, setdataCondition] = useState([]);
  const [loadingModal, setloadingModal] = useState(false);

  var list = [];
  var listConditions = [];
  var listCode = [];
  useEffect(() => {
    setLoading(true);
    // get all gratuits
    getAllGratuites()
      .then((res) => {
        setfreeProduct(res.data);

        for (let i = 0; i < res.data.length; i++) {
          // get all code gratuit product
          listCode.push(res.data[i].gratuit_code);
          //get product by code
          getImageProduct(res.data[i].gratuit_code).then((res) => {
            //get producto object
            list.push(res);
          });
          setTimeout(() => {
            // order product list
            var orderListProduct = [];
            for (let i = 0; i < list.length; i++) {
              orderListProduct.push(list.find((o) => o?.code == listCode[i]));
            }
            // product gratuit list
            setdata(orderListProduct);
            setLoading(false);
          }, 5000);
        }
      })
      .catch((error) => setError(error))
      .finally(setLoading(false));
  }, []);

  /**
   * fetch product by code
   * @param {string} code
   * @returns
   */
  async function getImageProduct(code) {
    const result = await getProductByCode(code);
    return result.data[0];
  }

  /**
   * open modal to show gratuit condition
   * @param {string} value
   */
  const handleSaveCode = (value, index) => {
    setloadingModal(true);
    setcode(value);
    getAllGratuitesConditions().then((res) => {
      const cond = res.data.filter((el) => {
        return el.gratuite_code == value;
      });
      setconditions(cond);

      for (let i = 0; i < cond.length; i++) {
        getImageProduct(cond[i].produit_code).then((res) => {
          listConditions.push(res);
        });
      }
      setTimeout(() => {
        setdataCondition(listConditions);
        setloadingModal(false);
      }, 2000);
    });
  };

  /**
   * add product to cart
   * @param {Object} item
   */
  const handleClick = () => {
    for (let i = 0; i < dataCondition.length; i++) {
      props.addToCart(dataCondition[i]);
    }
    toast(" 🛒 Produit ajouté");
  };

  return (
    <>
      {loading && (
        <div className="container mt-5">
          <div className="d-flex align-items-center justify-content-center h-100">
            <CustomSyncLoader></CustomSyncLoader>
          </div>
        </div>
      )}
      {!loading && error && (
        <div>
          <ErrorAlert msg={`Erreur: ${error}`}></ErrorAlert>
        </div>
      )}
      {!loading && !error && data.length > 0 && (
        <div className="container mt-2">
          <article className="card">
            <header className="card-header"> Produits gratuits </header>
            <div className="card-body">
              <div className="row">
                {freeProduct.map((el, index) => (
                  <div key={el.id} className="col-md-3">
                    <figure className="card card-product-grid  mb-3">
                      <div className="img-wrap">
                        <span className="badge badge-danger"> Gratuit </span>
                        <img
                          src={data[index]?.image ? data[index]?.image : "/images/product/product.png"}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "/images/product/product.png";
                          }}
                        />
                      </div>
                      <figcaption className="info-wrap">
                        <a href="#" className="title text-truncate">
                          {el.libelle}
                        </a>
                        <p className="price mb-2">
                          X {el.quantite_gratuit} {data[index]?.libelle}
                        </p>
                        <div className="mb-3">
                          <span className="text-muted">{el.date_debut}</span>
                          <span className="text-muted"> / {el.date_fin}</span>
                        </div>
                        <a
                          href="#"
                          className="btn btn-primary btn-sm"
                          data-toggle="tooltip"
                          title="Voir condition"
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                          onClick={() => handleSaveCode(el.code, index)}
                        >
                          Voir conditions
                        </a>
                      </figcaption>
                    </figure>
                  </div>
                ))}
              </div>
            </div>
          </article>

          {/* Modal */}
          <div
            className="modal fade"
            id="exampleModalCenter"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    Condition : {code}
                  </h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>

                {loadingModal && (
                  <div className="container mt-5">
                    <div className="d-flex align-items-center justify-content-center h-100">
                      <CustomSyncLoader></CustomSyncLoader>
                    </div>
                  </div>
                )}
                {!loadingModal && error && (
                  <div>
                    <ErrorAlert msg={`Erreur: ${error}`}></ErrorAlert>
                  </div>
                )}
                {!loadingModal && !error && dataCondition && (
                  <>
                    <div className="modal-body">
                      <article className="card mb-4">
                        <div className="card-body">
                          <h4 className="card-title mb-4">-</h4>
                          <div className="row">
                            {conditions.map((el, index) => (
                              <div key={el.id} className="col-md-6">
                                <figure className="itemside  mb-4">
                                  <div className="aside">
                                    <img
                                      src={
                                        dataCondition[index]?.image
                                          ? dataCondition[index]?.image
                                          : "/images/product/product.png"
                                      }
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = "/images/product/product.png";
                                      }}
                                      className="border img-sm"
                                    />
                                  </div>
                                  <figcaption className="info">
                                    <p>{dataCondition[index]?.libelle}</p>
                                    <span className="text-muted">{el.qte} X</span>
                                    {/* <Link
                                to="#"
                                className="btn btn-sm btn-outline-primary float-right mt-2"
                                onClick={() =>
                                  handleClick(dataCondition[index])
                                }
                              >
                                Ajouter au panier
                                <i className="fa fa-shopping-cart" />
                              </Link> */}
                                  </figcaption>
                                </figure>
                              </div>
                            ))}
                          </div>
                        </div>
                      </article>
                    </div>
                    <div className="modal-footer">
                      <Link
                        to="#"
                        className="btn btn-sm btn-outline-primary float-right mt-2"
                        onClick={() => handleClick()}
                      >
                        Ajouter au panier
                        <i className="fa fa-shopping-cart" />
                      </Link>
                      <button type="button" className="btn btn-light" data-dismiss="modal">
                        Fermer
                      </button>
                    </div>
                    <ToastContainer></ToastContainer>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {!loading && !error && data.length === 0 && <p></p>}
    </>
  );
}

const mapStateToProps = (state) => {
  return { products: state.products };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (item) => {
      dispatch(actionCreators.addToCart(item));
    },
    addGratuits: (item) => {
      dispatch(actionCreators.addGratuits(item));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Gratuite);

import React from "react";
import Brands from "../components/pages/Brands";
import DownloadApp from "../components/pages/DownloadApp";
import Offres from "../components/pages/Offres";
import Subscribe from "../components/pages/Subscribe";
import ButtomNavigation from "../components/shared/ButtomNavigation";
import Footer from "../components/shared/Footer";
import Header from "../components/shared/Header";
import Navbar from "../components/shared/Navbar";
import TopNavbar from "../components/shared/TopNavbar";

/**
 * main shop componets
 * @param {*} props
 * @returns
 */
function ShopList(props) {
  return (
    <React.Fragment>
      {/* ========================= TOP BAR ============================ */}
      <TopNavbar></TopNavbar>
      {/* ========================= TOP HEADER ========================= */}
      <Header></Header>
      {/* ========================= NAVBAR ============================= */}
      {/* <Navbar></Navbar> */}
      {/* ========================= SECTION MAIN ======================= */}
      {props.children}
      {/* ========================= BRANDS ============================= */}
      <Brands></Brands>
      {/* ========================= DOWNLOAD APP ======================= */}
      <DownloadApp></DownloadApp>
      {/* ========================= FEATURES =========================== */}
      <Offres></Offres>
      {/* ========================= FOOTER ============================= */}
      <Subscribe></Subscribe>
      <Footer></Footer>
      <ButtomNavigation></ButtomNavigation>
    </React.Fragment>
  );
}

export default ShopList;

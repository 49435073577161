import axios from "axios";
require("dotenv").config();


const api_url = process.env.REACT_APP_API_ENDPOINT;

/**
 * get all Familles
 */
export async function getAllFamilles() {
  try {
    const response = await axios.get(api_url + "familles", {
      withCredentials: true,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * get product by familles
 */
export async function getProductByFamilles(id) {
  try {
    const response = await axios.get(api_url + "produits/productListByColumn/familles/"+id, {
      withCredentials: true,
    });
    //var data_filter = response.data.filter(el => el.vendable_web == "1")
    return response;
  } catch (error) {
    console.error(error);
  }
}

import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
require("dotenv").config();

const api_url = process.env.REACT_APP_API_ENDPOINT;

/**
 * get all gratuites
 */
export async function getAllGratuites() {
  try {
    const response = await axios.get(api_url + "gratuites/mobile", {
      withCredentials: true,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * get all gratuites conditions
 */
export async function getAllGratuitesConditions() {
  try {
    const response = await axios.get(api_url + "gratuites/conditions", {
      withCredentials: true,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

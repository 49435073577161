import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getAllGammes } from "../../services/GammeApi";
import { stringToLowwerCase } from "../../Utils/StringOperation";
import ErrorAlert from "./../../others/ErrorAlert";
import CustomSyncLoader from "./../../others/CustomSyncLoader";

function Menu() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);

  useEffect(() => {
    setLoading(true);
    getAllGammes()
      .then((res) => {
        setdata(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);
  return (
    <>
      {loading && (
        <div className="container mt-5">
          <div className="d-flex align-items-center justify-content-center h-100">
            <CustomSyncLoader></CustomSyncLoader>
          </div>
        </div>
      )}
      {!loading && error && (
        <div>
          <ErrorAlert msg={`Erreur: ${error}`}></ErrorAlert>
        </div>
      )}
      {!loading && !error && data && (
        <section className="section-main bg padding-y">
          <div className="container">
            <div className="row">
              <aside className="col-md-3">
                <nav className="card">
                  <ul className="menu-category">
                    {data.slice(0, 6).map((el, i) => (
                      <li key={i}>
                        <Link to={"/filtergamme/" + el.code}>{stringToLowwerCase(el.libelle)}</Link>
                      </li>
                    ))}

                    <li className="has-submenu">
                      <a href="#">Autres gammes</a>
                      <ul className="submenu">
                        {data.slice(6, data.length).map((el, i) => (
                          <li key={i}>
                            <Link to={"/filtergamme/" + el.code}>{stringToLowwerCase(el.libelle)}</Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  </ul>
                </nav>
              </aside>
              <div className="col-md-9">
                <div className="container m-2">
                  <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                    <ol className="carousel-indicators">
                      <li data-target="#carouselExampleIndicators" data-slide-to={0} className="active" />
                      <li data-target="#carouselExampleIndicators" data-slide-to={1} />
                      <li data-target="#carouselExampleIndicators" data-slide-to={2} />
                    </ol>
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img
                          className="d-block w-100"
                          src="images/banners/b1.png"
                          alt="First slide"
                          style={{ height: 280 }}
                        />
                        {/* <div className="carousel-caption d-none d-md-block">
                          <h5>lorem</h5>
                          <p>lorem lorem lorem lorem</p>
                        </div> */}
                      </div>
                      <div className="carousel-item">
                        <img
                          className="d-block w-100"
                          src="images/banners/c1.jpg"
                          alt="Second slide"
                          style={{ height: 280 }}
                        />
                        {/* <div className="carousel-caption d-none d-md-block">
                          <h5>lorem</h5>
                          <p>lorem lorem lorem lorem</p>
                        </div> */}
                      </div>
                      <div className="carousel-item">
                        <img
                          className="d-block w-100"
                          src="images/banners/c3.jpg"
                          alt="Third slide"
                          style={{ height: 280 }}
                        />
                        {/* <div className="carousel-caption d-none d-md-block">
                          <h5>lorem</h5>
                          <p>lorem lorem lorem lorem</p>
                        </div> */}
                      </div>
                    </div>
                    <a
                      className="carousel-control-prev"
                      href="#carouselExampleIndicators"
                      role="button"
                      data-slide="prev"
                    >
                      <span className="carousel-control-prev-icon" aria-hidden="true" />
                      <span className="sr-only">Previous</span>
                    </a>
                    <a
                      className="carousel-control-next"
                      href="#carouselExampleIndicators"
                      role="button"
                      data-slide="next"
                    >
                      <span className="carousel-control-next-icon" aria-hidden="true" />
                      <span className="sr-only">Next</span>
                    </a>
                  </div>
                </div>
              </div>
              {/* <div className="col-md d-none d-lg-block flex-grow-1">
                <aside className="special-home-right">
                  <h6 className="bg-blue text-center text-white mb-0 p-2">Produits populaires</h6>
                  <div className="card-banner border-bottom">
                    <div className="py-3" style={{ width: "80%" }}>
                      <h6 className="card-title">Men clothing</h6>
                      <a href="#" className="btn btn-secondary btn-sm">
                        {" "}
                        Source now{" "}
                      </a>
                    </div>
                    <img src="images/product/test.jpg" height={80} className="img-bg" />
                  </div>
                  <div className="card-banner border-bottom">
                    <div className="py-3" style={{ width: "80%" }}>
                      <h6 className="card-title">Winter clothing </h6>
                      <a href="#" className="btn btn-secondary btn-sm">
                        {" "}
                        Source now{" "}
                      </a>
                    </div>
                    <img src="images/product/test.jpg" height={80} className="img-bg" />
                  </div>
                  <div className="card-banner border-bottom">
                    <div className="py-3" style={{ width: "80%" }}>
                      <h6 className="card-title">Home inventory</h6>
                      <a href="#" className="btn btn-secondary btn-sm">
                        {" "}
                        Source now{" "}
                      </a>
                    </div>
                    <img src="images/product/test.jpg" height={80} className="img-bg" />
                  </div>
                </aside>
              </div>{" "}
             */}
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default Menu;

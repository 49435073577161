import React from "react";

function Offres() {
  return (
    <section className="section-specials padding-y border-bottom">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <figure className="itemside">
              <div className="aside">
                <span className="icon-sm rounded-circle bg-primary">
                  <i className="fa fa-money-bill-alt white" />
                </span>
              </div>
              <figcaption className="info">
                <h6 className="title">Achetez en toute confiance</h6>
                <p className="text-muted">
                Notre Protection de l'acheteur vous protège du premier clic à la commande.
                </p>
              </figcaption>
            </figure>
            {/* iconbox // */}
          </div>
          {/* col // */}
          <div className="col-md-4">
            <figure className="itemside">
              <div className="aside">
                <span className="icon-sm rounded-circle bg-danger">
                  <i className="fa fa-comment-dots white" />
                </span>
              </div>
              <figcaption className="info">
                <h6 className="title">24/7 Centre d'Aide </h6>
                <p className="text-muted">
                A toute heure, vous pouvez contacter le Centre d'Aide pour une expérience d'achat facile.
                </p>
              </figcaption>
            </figure>
            {/* iconbox // */}
          </div>
          {/* col // */}
          <div className="col-md-4">
            <figure className="itemside">
              <div className="aside">
                <span className="icon-sm rounded-circle bg-success">
                  <i className="fa fa-truck white" />
                </span>
              </div>
              <figcaption className="info">
                <h6 className="title">Qualité et Prix</h6>
                <p className="text-muted">
                Nous offrons des prix compétitifs sur notre large gamme de produits.
                </p>
              </figcaption>
            </figure>
            {/* iconbox // */}
          </div>
          {/* col // */}
        </div>
        {/* row.// */}
      </div>
      {/* container.// */}
    </section>
  );
}

export default Offres;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CustomSyncLoader from "../../others/CustomSyncLoader";
import ErrorAlert from "./../../others/ErrorAlert";
import { getProductByGammes } from "../../services/GammeApi";
import { useHistory } from "react-router-dom";
import { isLogin } from "../../Utils/SessionUtils";

function PopularProduct(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);

  useEffect(() => {
    setLoading(true);
    getProductByGammes(props.gammeCode)
      .then((res) => {
        setdata(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  /**
   * navigate to product detail by code
   * @param {string} value
   */
  const handleProductClick = (value) => {
    history.push("/detail/" + value);
    history.go(0);
  };

  return (
    <output>
      <section className="section-content mt-2">
        <div className="container">
          <div className="card card-body">
            <h4 className="card-title mb-2">Vous aimerez aussi</h4>
            {loading && (
              <div className="container mt-5">
                <div className="d-flex align-items-center justify-content-center h-100">
                  <CustomSyncLoader></CustomSyncLoader>
                </div>
              </div>
            )}
            {!loading && error && (
              <div>
                <ErrorAlert msg={`Erreur: ${error}`}></ErrorAlert>
              </div>
            )}
            {!loading && !error && data && (
              <div className="row">
                {data.slice(data.length - 5, data.length - 1).map((el) => (
                  <div key={el.id} className="col-md-3">
                    <figure className="itemside mb-2">
                      <div className="aside">
                        <img
                          src={el.image == null || el.image == "" ? "/images/product/product.png" : el.image}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "/images/product/product.png";
                          }}
                          className="border img-sm"
                        />
                      </div>
                      <figcaption className="info align-self-center">
                        <Link to="#" className="title" onClick={() => handleProductClick(el.code)}>
                          {el.libelle}
                        </Link>
                        <strong className="price">
                          {isLogin() ? el.prix_vente_nomashop_2 : el.prix_vente_nomashop_1} DT
                        </strong>
                      </figcaption>
                    </figure>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </section>
    </output>
  );
}

export default PopularProduct;

import React, { useState } from "react";
import { Link } from "react-router-dom";

function TrackCommandeGuest() {
  const [codeCommande, setcodeCommande] = useState("");
  return (
    <div className="container mt-2 mb-2">
      <section className="content">
        <div className="container-fluid">
          <h2 className="text-center display-4">Recherche</h2>
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="input-group">
                <input
                  type="search"
                  className="form-control form-control-lg"
                  placeholder="Tapez votre code de commande ..."
                  onChange={(e) => setcodeCommande(e.target.value)}
                />
                <div className="input-group-append">
                  <Link to={"/trackorder/" + codeCommande}>
                    <button type="submit" className="btn btn-lg btn-default">
                      <i className="fa fa-search" />
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="d-flex justify-content-center">
        <img
          src="images/search.svg"
          alt="empty commnade"
          style={{ width: "300px", height: "300px" }}
        />
      </div>
    </div>
  );
}

export default TrackCommandeGuest;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CustomSyncLoader from "../../others/CustomSyncLoader";
import { filterByWord, priceBetween, sortDateAsc, sortPriceAsc, sortPriceDesc } from "../../Utils/SortObject";
import ProductCardFull from "../Card/ProductCardFull";
import { getAllFamilles } from "../../services/FamilleApi";
import { getAllGammes, getProductByGammes } from "../../services/GammeApi";
import { useHistory } from "react-router-dom";
import Pagination from "../Pagination/Pagination";
import ErrorAlert from "./../../others/ErrorAlert";
import { getAllSousFamilles } from "../../services/SousFamilleApi";

function FilterGamme(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [search, setsearch] = useState("");
  const [currentData, setcurrentData] = useState([]);
  const [data, setdata] = useState([]);
  const [sortProduct, setsortProduct] = useState("1");
  const [min, setmin] = useState("");
  const [max, setmax] = useState("");
  const [filtredGamme, setfiltredGamme] = useState([]);
  const [filtredFamille, setfiltredFamille] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [filtredSousFamille, setfiltredSousFamille] = useState([]);

  useEffect(() => {
    handleSelectGamme(props.match.params.id);
  }, []);

  useEffect(() => {
    setLoading(true);
    getAllFamilles()
      .then((res) => {
        setfiltredFamille(res.data);
      })
      .catch((error) => setError(error));

    getAllGammes()
      .then((res) => {
        setfiltredGamme(res.data);
      })
      .catch((error) => setError(error));

    getAllSousFamilles()
      .then((res) => {
        setfiltredSousFamille(res.data);
      })
      .catch((error) => setError(error));
  }, []);

  /**
   * handle search event
   * @param {event} e
   */
  const handleSearch = (e) => {
    setsearch(e.target.value);

    // if search not empty
    if (e.target.value) {
      // filter by word
      let newList = filterByWord(data, e.target.value);
      //setdata(newList);
      //paginateData(currentPage, newList);
      setdata(newList);
    } else {
      //if search is empty
      //paginateData(currentPage, data);
      setdata(allInitialData);
    }
  };

  /**
   * sort object
   * @param {event} e
   */
  const handleSortProduct = (e) => {
    setsortProduct(e.target.value);
    // if sort is price asc
    if (e.target.value === "2") {
      const sortAscPrice = sortPriceAsc(data);
      //setdata(sortAscPrice);
      setdata(sortAscPrice);

      // if sort is price desc
    } else if (e.target.value === "3") {
      const sortDescPrice = sortPriceDesc(data);
      //setdata(sortDescPrice);
      setdata(sortDescPrice);
      // if sort is date desc
    } else {
      const sortAscDate = sortDateAsc(data);
      //console.log(sortAscDate)
      setdata(sortAscDate);
      // TODO change to sortAscDate
      //paginateData(currentPage, data);
    }
  };

  /**
   * filter proudct by gamme name
   * @param {string} gamme
   */
  const handleSelectGamme = (gamme) => {
    // group products by gamme
    // search by gamme name
    setLoading(true);
    getProductByGammes(gamme)
      .then((res) => {
        setdata(res.data);
        setallInitialData(res.data);
        console.log(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  /**
   * filter product by famille
   * @param {string} famille
   */
  const handleSelectFamille = (famille) => {
    // group products by famille
    // search by famille name
    history.push("/filterfamille/" + famille);
  };

  /**
   * filter proudct by sousfamille name
   * @param {string} sousfamille
   */
  const handleSelectSousFamille = (sousfamille) => {
    // group products by gamme
    // search by gamme name
    history.push("/filtersousfamille/" + sousfamille);
  };

  /**
   * filter product by price
   */
  const handleConfirmPrice = () => {
    // filter by price
    const priceFilter = priceBetween(data, min, max);
    //setdata(priceFilter);
    setdata(priceFilter);
  };

  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };

  return (
    <div>
      <section className="mt-4">
        {/* <div className="container">
          <h2 className="title-page">Catégories des produits</h2>
        </div> */}
      </section>
      <section className="section-content">
        <div className="container">
          <div className="row">
            <aside className="col-md-3">
              <div className="card">
                <article className="filter-group">
                  <header className="card-header">
                    <a href="#" data-toggle="collapse" data-target="#collapse_1" aria-expanded="false">
                      <i className="icon-control fa fa-chevron-down" />
                      <h6 className="title">Gamme</h6>
                    </a>
                  </header>
                  <div className="filter-content collapse show" id="collapse_1">
                    <div className="card-body">
                      <form className="pb-3">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Rechercher ..."
                            value={search || ""}
                            onChange={(e) => handleSearch(e)}
                          />
                          <div className="input-group-append">
                            <button className="btn btn-light" type="button">
                              <i className="fa fa-search" />
                            </button>
                          </div>
                        </div>
                      </form>
                      <ul className="list-menu">
                        {filtredGamme.map((el, index) => (
                          <li key={index}>
                            <Link to={"/filtergamme/" + el.code} onClick={() => handleSelectGamme(el.code)}>
                              {el.libelle}
                            </Link>
                            {/* <span className="badge badge-pill badge-light float-right">
                              {el.PRODUIT.length}
                            </span> */}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </article>
                <article className="filter-group">
                  <header className="card-header">
                    <a href="#" data-toggle="collapse" data-target="#collapse_2" aria-expanded="false">
                      <i className="icon-control fa fa-chevron-down" />
                      <h6 className="title">Famille </h6>
                    </a>
                  </header>
                  <div className="filter-content collapse show" id="collapse_2" style={{}}>
                    <div className="card-body">
                      {filtredFamille.map((el, index) => (
                        <div key={index} className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            defaultChecked
                            onClick={() => handleSelectFamille(el.code)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2"
                            onClick={() => handleSelectFamille(el.code)}
                          >
                            {el.libelle}
                          </label>

                          {/* <b className="badge badge-pill badge-light float-right">
                            {el.PRODUIT.length}
                          </b> */}
                        </div>
                      ))}
                    </div>
                  </div>
                </article>
                <article className="filter-group">
                  <header className="card-header">
                    <a href="#" data-toggle="collapse" data-target="#collapse_3" aria-expanded="false">
                      <i className="icon-control fa fa-chevron-down" />
                      <h6 className="title">Prix </h6>
                    </a>
                  </header>
                  <div className="filter-content collapse show" id="collapse_3" style={{}}>
                    <div className="card-body">
                      {/* <input
                        type="range"
                        className="custom-range"
                        min={0}
                        max={1000}
                      /> */}
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label>Min</label>
                          <input
                            className="form-control"
                            placeholder="0 DT"
                            type="number"
                            value={min}
                            onChange={(e) => setmin(e.target.value)}
                          />
                        </div>
                        <div className="form-group text-right col-md-6">
                          <label>Max</label>
                          <input
                            className="form-control"
                            placeholder="1,0000 DT"
                            type="number"
                            value={max}
                            onChange={(e) => setmax(e.target.value)}
                          />
                        </div>
                      </div>
                      <button className="btn btn-block btn-primary" onClick={handleConfirmPrice}>
                        Appliquer
                      </button>
                    </div>
                  </div>
                </article>
                <article className="filter-group">
                  <header className="card-header">
                    <a href="#" data-toggle="collapse" data-target="#collapse_5" aria-expanded="false">
                      <i className="icon-control fa fa-chevron-down" />
                      <h6 className="title">Autres filters </h6>
                    </a>
                  </header>

                  <div className="filter-content collapse in" id="collapse_5" style={{}}>
                    <div className="card-body">
                      {filtredSousFamille.map((el) => (
                        <label key={el.id} className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="myfilter_radio"
                            defaultChecked
                            className="custom-control-input"
                            onClick={() => handleSelectSousFamille(el.code)}
                          />
                          <div className="custom-control-label">{el.libelle}</div>
                        </label>
                      ))}
                    </div>
                    {/* card-body.// */}
                  </div>
                </article>
              </div>
            </aside>
            <main className="col-md-9">
              <header className="border-bottom mb-4 pb-3">
                <div className="form-inline">
                  <span className="mr-md-auto">{data.length} Produits trouvés</span>
                  <select value={sortProduct} onChange={(e) => handleSortProduct(e)} className="mr-2 form-control">
                    <option value="1">Derniers produits</option>
                    <option value="2">Le moins cher</option>
                    <option value="3">Le plus cher</option>
                  </select>
                  <div className="btn-group">
                    <Link to="#" className="btn  btn-outline-secondary active" data-toggle="tooltip" title="Grid view">
                      <i className="fa fa-th" />
                    </Link>

                    {/* <Link
                      to="/productlist"
                      className="btn btn-outline-secondary"
                      data-toggle="tooltip"
                      title="List view"
                    >
                      <i className="fa fa-bars" />
                    </Link> */}
                  </div>
                </div>
              </header>
              {/* sect-heading */}
              {loading && (
                <div className="container mt-5">
                  <div className="d-flex align-items-center justify-content-center h-100">
                    <CustomSyncLoader></CustomSyncLoader>
                  </div>
                </div>
              )}
              {!loading && error && <ErrorAlert msg={`Erreur: ${error}`}></ErrorAlert>}
              {!loading && !error && data && (
                <>
                  <div className="row">
                    <ProductCardFull itemsList={currentData}></ProductCardFull>
                  </div>
                  <Pagination items={data} onChangePage={onChangePage} />
                </>
              )}
            </main>
          </div>
        </div>
      </section>
    </div>
  );
}

export default FilterGamme;

import axios from "axios";
require("dotenv").config();

const api_url = process.env.REACT_APP_API_ENDPOINT;

/**
 * get all Familles
 */
export async function getEntrepriseDetails() {
  try {
    const response = await axios.get(api_url + "societes", {
      withCredentials: true,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
require("dotenv").config();

const api_url = process.env.REACT_APP_API_ENDPOINT;

/**
 * add commande
 * @returns
 */
export async function addCommande(commandeObject) {
  try {
    const response = await axios.post(
      api_url + "storeCommandeWeb",
      commandeObject
    );
    toast.info(" ✔️ Commande ajouté");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * get all commandes
 */
export async function getAllCommandes() {
  const user = JSON.parse(localStorage.getItem("user"));
  try {
    const response = await axios.get(
      api_url + "entetecommercial/byclientcode/" + user.code,
      {
        withCredentials: true,
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * get params commande
 */
export async function getParamCommande() {
  try {
    const response = await axios.get(
      api_url + "parametrages_nomashop_commande",
      {
        withCredentials: true,
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * get commande by code
 */
export async function getCommandeByCode(id) {
  try {
    const response = await axios.get(
      api_url + "gestion_commandes/ByCode/" + id,
      {
        withCredentials: true,
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

import React, { useEffect, useState } from "react";
import { getProductByCode } from "../../services/ProductApi";
import { getAllPromotions, getPromotionProduit } from "../../services/PromotionApi";
import ErrorAlert from "./../../others/ErrorAlert";
import CustomSyncLoader from "./../../others/CustomSyncLoader";
import { connect } from "react-redux";
import * as actionCreators from "../../actions/index";
import { Link } from "react-router-dom";
import { mergeObjectByPromotionCode } from "../../Utils/ObjectOperation";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Promotion(props) {
  const [dataPromotion, setdataPromotion] = useState([]);
  const [productObject, setProductObject] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  var listCodePromotion = [];
  var productArrayObject = [];

  useEffect(() => {
    setLoading(true);
    getAllPromotions()
      .then((promotion) => {
        const promotions = promotion.data;

        getPromotionProduit()
          .then((res) => {
            const promotionProduct = res.data;
            // console.log(res);
            // merge promotion list and promotion condition
            const mergePromotion = mergeObjectByPromotionCode(promotions, promotionProduct);
            // console.log(mergePromotion);
            setdataPromotion(mergePromotion);
            console.log(mergePromotion);

            for (let i = 0; i < mergePromotion.length; i++) {
              //get all code produit from promotion produit
              listCodePromotion.push(mergePromotion[i].produit_code);
              // let productCode =
              //   mergePromotion[i].produit_code ||
              //   mergePromotion[i].sousfamille_code ||
              //   mergePromotion[i].famille_code ||
              //   mergePromotion[i].marque_code ||
              //   mergePromotion[i].gamme_code;
              // console.log(productCode);

              // if (!mergePromotion[i].produit_code) {
              //   console.log("without code");
              // } else {
              //   console.log("with code");
              // }

              getProductByCode(mergePromotion[i].produit_code).then((res) => {
                productArrayObject.push(res.data[0]);
              });
            }
          })
          .catch((error) => setError(error));

        //wait until listCodePromotion product not be empty
        setTimeout(() => {
          // oder listCodePromotion product by promotion code product
          var orderListProduct = [];
          for (let i = 0; i < productArrayObject.length; i++) {
            orderListProduct.push(productArrayObject.find((o) => o?.code == listCodePromotion[i]));
          }

          // const cleanObject = orderListProduct.filter(function (element) {
          //   return element !== undefined;
          // });

          // console.log(
          //   "🚀 ~ file: Promotion.js ~ line 74 ~ setTimeout ~ orderListProduct",
          //   cleanObject
          // );
          setProductObject(orderListProduct);
          setLoading(false);
        }, 2000);
      })
      .catch((error) => setError(error));
  }, []);

  const handleClick = (i) => {
    const item = productObject[i];
    props.addToCart(item);
    toast(" 🛒 Produit ajouté");
  };

  return (
    <>
      {loading && (
        <div className="container mt-5">
          <div className="d-flex align-items-center justify-content-center h-100">
            <CustomSyncLoader></CustomSyncLoader>
          </div>
        </div>
      )}
      {!loading && error && (
        <div>
          <ErrorAlert msg={`Erreur: ${error}`}></ErrorAlert>
        </div>
      )}
      {!loading && !error && productObject.length > 0 && (
        <div className="container">
          <article className="card">
            <header className="card-header"> Promotions </header>
            <div className="card-body">
              <div className="row">
                {dataPromotion.map((el, index) => (
                  <div key={el.id} className="col-md-3">
                    <figure className="itemside mb-4">
                      <div className="aside">
                        {el.produit_code == null ? (
                          el.gamme_code != null ? (
                            <Link to={"/filtergamme/" + el.gamme_code}>
                              <img
                                src={
                                  productObject[index]?.image
                                    ? productObject[index]?.image
                                    : "/images/product/discount.png"
                                }
                                className="border img-md"
                              />
                            </Link>
                          ) : el.famille_code != null ? (
                            <Link to={"/filterfamille/" + el.famille_code}>
                              <img
                                src={
                                  productObject[index]?.image
                                    ? productObject[index]?.image
                                    : "/images/product/discount.png"
                                }
                                className="border img-md"
                              />
                            </Link>
                          ) : el.sousfamille_code != null ? (
                            <Link to={"/filtersousfamille/" + el.sousfamille_code}>
                              <img
                                src={
                                  productObject[index]?.image
                                    ? productObject[index]?.image
                                    : "/images/product/discount.png"
                                }
                                className="border img-md"
                              />
                            </Link>
                          ) : (
                            <p></p>
                          )
                        ) : (
                          <Link to={"/detail/" + productObject[index]?.code}>
                            <img
                              src={
                                productObject[index]?.image
                                  ? productObject[index]?.image
                                  : "/images/product/discount.png"
                              }
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/images/product/discount.png";
                              }}
                              className="border img-md"
                            />
                          </Link>
                        )}
                      </div>
                      <figcaption className="info">
                        <Link to={"/detail/" + productObject[index]?.code} className="title">
                          {el.libelle}
                        </Link>
                        <small className="label-rating text-muted">{el.remise} % remise</small>
                        <small className="label-rating text-success">
                          <i className="fa fa-clipboard-check"></i> {el.qte_min}
                          pièces achetées
                        </small>
                        <div className="mb-3">
                          <span className="text-muted">{el.date_debut}</span>
                          <span className="text-muted"> / {el.date_fin}</span>
                        </div>
                        {el.produit_code == null ? (
                          el.gamme_code != null ? (
                            <Link to={"/filtergamme/" + el.gamme_code} className="btn btn-primary btn-sm">
                              Voir promotions
                            </Link>
                          ) : el.famille_code != null ? (
                            <Link to={"/filterfamille/" + el.famille_code} className="btn btn-primary btn-sm">
                              Voir promotions
                            </Link>
                          ) : (
                            <p></p>
                          )
                        ) : (
                          <Link to="#" onClick={() => handleClick(index)} className="btn btn-primary btn-sm">
                            Ajouter au panier
                          </Link>
                        )}
                      </figcaption>
                    </figure>
                  </div>
                ))}
                <ToastContainer></ToastContainer>
              </div>
            </div>
          </article>
        </div>
      )}
      {!loading && !error && productObject.length === 0 && <p></p>}
    </>
  );
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (id) => {
      dispatch(actionCreators.addToCart(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Promotion);

import React from "react";

function GratuitsCard(props) {
  return (
    <article className="card mb-4">
      <div className="card-body">
        <h4 className="card-title mb-4">Produit Gratuit</h4>
        <div className="row">
          {props.gratuiProduct.map((el, index) => (
            <div key={index} className="col-md-6">
              <figure className="itemside  mb-4">
                <div className="aside">
                  <img
                    src={el[0].image}
                    className="border img-sm"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "/images/product/product.png";
                    }}
                  />
                </div>
                <figcaption className="info">
                  <p>{el[0].libelle} </p>
                  <span className="text-muted">
                    {el[0].cartQuantity} X Gratuit
                  </span>
                </figcaption>
              </figure>
            </div>
          ))}
        </div>
      </div>
    </article>
  );
}

export default GratuitsCard;

import React, { useState, useEffect } from "react";
import CustomSyncLoader from "../../others/CustomSyncLoader";
import ProductCard from "../Card/ProductCard";
import { getTopProducts } from "../../services/ProductApi";
import ErrorAlert from "./../../others/ErrorAlert";

function NewArrived() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);

  useEffect(() => {
    setLoading(true);
    getTopProducts()
      .then((res) => {
        console.log(res);
        setdata(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  return (
    <section className="section-content">
      <div className="container">
        <header className="section-heading">
          <h3 className="section-title">Nouveau arrivé</h3>
        </header>
        {loading && (
          <div className="container mt-5">
            <div className="d-flex align-items-center justify-content-center h-100">
              <CustomSyncLoader></CustomSyncLoader>
            </div>
          </div>
        )}
        {!loading && error && (
          <div>
            <ErrorAlert msg={`Erreur: ${error}`}></ErrorAlert>
          </div>
        )}
        {!loading && !error && data && (
          <div className="row">
            <ProductCard itemsList={data.slice(0, 8)}></ProductCard>
          </div>
        )}
      </div>
    </section>
  );
}
export default NewArrived;

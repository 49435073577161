import React from "react";

function Subscribe() {
  return (
    <section className="padding-y-lg bg-light border-top">
      <div className="container">
        <p className="pb-2 text-center">
          Fournissez les dernières tendances en matière de produits et les nouvelles de l'industrie directement dans
          votre boîte de réception
        </p>
        <div className="row justify-content-md-center">
          <div className="col-lg-4 col-sm-6">
            <form className="form-row">
              <div className="col-8">
                <input className="form-control" placeholder="email ..." type="email" />
              </div>
              {/* col.// */}
              <div className="col-4">
                <button type="submit" className="btn btn-block btn-warning">
                  <i className="fa fa-envelope" /> S'abonner
                </button>
              </div>
              {/* col.// */}
            </form>
            <small className="form-text">Nous ne partagerons jamais votre adresse e-mail avec un tiers. </small>
          </div>
          {/* col-md-6.// */}
        </div>
      </div>
    </section>
  );
}

export default Subscribe;

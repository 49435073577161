import React, { useEffect, useState } from "react";
import { getProductByCode } from "../../services/ProductApi";
import Review from "./Review";
import { connect } from "react-redux";
import * as actionCreators from "../../actions/index";
import { Link } from "react-router-dom";
import ErrorAlert from "./../../others/ErrorAlert";
import CustomSyncLoader from "./../../others/CustomSyncLoader";
import Recommended from "./Recommended";
import PopularProduct from "./PopularProduct";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isLogin } from "../../Utils/SessionUtils";

function DetailProduct(props) {
  const [imageProduct, setImageProduct] = useState("");
  const [id, setid] = useState("");
  const [singleProduct, setsingleProduct] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    getProductByCode(props.match.params.id)
      .then((res) => {
        setsingleProduct(res.data[0]);
        setImageProduct(res.data[0].image);
        setid(res.data[0].id);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  const handleClick = (item) => {
    props.addToCart(item);
    toast(" 🛒 Produit ajouté");
  };

  return (
    <section className="section-content padding-y bg">
      {loading && (
        <div className="container mt-5">
          <div className="d-flex align-items-center justify-content-center h-100">
            <CustomSyncLoader></CustomSyncLoader>
          </div>
        </div>
      )}
      {!loading && error && (
        <div>
          <ErrorAlert msg={`Erreur: ${error}`}></ErrorAlert>
        </div>
      )}
      {!loading && !error && (
        <div className="container">
          <div className="card">
            <div className="row no-gutters">
              <aside className="col-md-6">
                <article className="gallery-wrap">
                  <div className="img-big-wrap">
                    <a href="#" className="zoom">
                      <img
                        src={imageProduct ? imageProduct : "/images/product/product.png"}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "/images/product/product.png";
                        }}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="thumbs-wrap">
                    <a href="#" className="item-thumb">
                      <img
                        src={imageProduct ? imageProduct : "/images/product/product.png"}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "/images/product/product.png";
                        }}
                        alt=""
                      />
                    </a>
                    <a href="#" className="item-thumb">
                      <img
                        src={imageProduct ? imageProduct : "/images/product/product.png"}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "/images/product/product.png";
                        }}
                        alt=""
                      />
                    </a>
                    <a href="#" className="item-thumb">
                      <img
                        src={imageProduct ? imageProduct : "/images/product/product.png"}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "/images/product/product.png";
                        }}
                        alt=""
                      />
                    </a>
                    <a href="#" className="item-thumb">
                      <img
                        src={imageProduct ? imageProduct : "/images/product/product.png"}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "/images/product/product.png";
                        }}
                        alt=""
                      />
                    </a>
                  </div>
                </article>
              </aside>
              <main className="col-md-6 border-left">
                <article className="content-body">
                  <h2 className="title">{singleProduct.libelle}</h2>
                  <div className="rating-wrap my-3">
                    {/* <ul className="rating-stars">
                      <li style={{ width: "80%" }} className="stars-active">
                        <img src="/images/icons/stars-active.svg" alt="" />
                      </li>
                      <li>
                        <img src="/images/icons/starts-disable.svg" alt="" />
                      </li>
                    </ul> */}
                    <small className="label-rating text-muted">Date ajout : {singleProduct.created_at}</small>
                    <small className="label-rating text-success">
                      <i className="fa fa-clipboard-check" />
                      {singleProduct.colisage_nomashop} PCB
                    </small>
                  </div>
                  {/* rating-wrap.// */}
                  <div className="mb-3">
                    <var className="price h4">
                      {isLogin() ? singleProduct.prix_vente_nomashop_2 : singleProduct.prix_vente_nomashop_1}
                      DT
                    </var>
                    <span className="text-muted">/1</span>
                  </div>
                  <p>{singleProduct.fiche}</p>
                  <dl className="row">
                    <dt className="col-sm-3">Code</dt>
                    <dd className="col-sm-9">{singleProduct.code}</dd>
                    <dt className="col-sm-3">Code à barre</dt>
                    <dd className="col-sm-9">{singleProduct.code_a_barre} </dd>
                  </dl>
                  <hr />
                  {/* <div className="row">
               <div className="form-group col-md flex-grow-0">
                 <label>Quantity</label>
                 <div className="input-group mb-3 input-spinner">
                   <div className="input-group-prepend">
                     <button
                       className="btn btn-light"
                       type="button"
                       id="button-plus"
                     >
                       +
                     </button>
                   </div>
                   <input
                     type="text"
                     className="form-control"
                     defaultValue={1}
                   />
                   <div className="input-group-append">
                     <button
                       className="btn btn-light"
                       type="button"
                       id="button-minus"
                     >
                       −
                     </button>
                   </div>
                 </div>
               </div>
               <div className="form-group col-md">
                 <label>Select size</label>
                 <div className="mt-2">
                   <label className="custom-control custom-radio custom-control-inline">
                     <input
                       type="radio"
                       name="select_size"
                       defaultChecked
                       className="custom-control-input"
                     />
                     <div className="custom-control-label">Small</div>
                   </label>
                   <label className="custom-control custom-radio custom-control-inline">
                     <input
                       type="radio"
                       name="select_size"
                       className="custom-control-input"
                     />
                     <div className="custom-control-label">Medium</div>
                   </label>
                   <label className="custom-control custom-radio custom-control-inline">
                     <input
                       type="radio"
                       name="select_size"
                       className="custom-control-input"
                     />
                     <div className="custom-control-label">Large</div>
                   </label>
                 </div>
               </div>
             </div>
              */}
                  {/* <a href="#" className="btn  btn-primary">
               Acheter maintenant
             </a> */}
                  {/* <dl className="row">
                    <dt className="col-sm-3">Gamme</dt>
                    <dd className="col-sm-9">{singleProduct.gamme_code}</dd>
                    <dt className="col-sm-3">Famille</dt>
                    <dd className="col-sm-9">{singleProduct.famille_code} </dd>
                    <dt className="col-sm-3">Sous Famille</dt>
                    <dd className="col-sm-9">{singleProduct.sousfamille_code} </dd>
                  </dl> */}
                  <Link to={"/detail/" + id} className="btn  btn-outline-primary">
                    <span onClick={() => handleClick(singleProduct)} className="text">
                      Ajouter au panier
                    </span>
                    <i className="fas fa-shopping-cart" />
                  </Link>
                  <ToastContainer></ToastContainer>
                </article>
              </main>
            </div>
          </div>
          <br />
          <br />

          <article className="card">
            <div className="card-body">
              <div className="row">
                <aside className="col-md-6">
                  <h5>Prix</h5>
                  <dl className="row">
                    <dt className="col-sm-3">Prix HT</dt>
                    <dd className="col-sm-9">{singleProduct.prix_ht} DT</dd>
                    <dt className="col-sm-3">Prix TTC</dt>
                    <dd className="col-sm-9">
                      {isLogin() ? singleProduct.prix_vente_nomashop_2 : singleProduct.prix_vente_nomashop_1}
                      DT
                    </dd>
                    <dt className="col-sm-3">Prix promotion</dt>
                    <dd className="col-sm-9">{singleProduct.prix_promotion} DT</dd>
                    <dt className="col-sm-3">TVA</dt>
                    <dd className="col-sm-9">{singleProduct.tva} %</dd>
                  </dl>
                </aside>
                <aside className="col-md-6">
                  <h5>Caractéristiques</h5>
                  <ul className="list-check">
                    <li>Poids {singleProduct.poids}</li>
                  </ul>
                </aside>
              </div>
            </div>
            {/* card-body.// */}
          </article>
          <Recommended sousfamilleCode={singleProduct.sousfamille_code}></Recommended>
          <PopularProduct gammeCode={singleProduct.gamme_code}></PopularProduct>
        </div>
      )}
    </section>
  );
}

const mapStateToProps = (state) => {
  return { products: state.products };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (item) => {
      dispatch(actionCreators.addToCart(item));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailProduct);

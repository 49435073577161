const initialState = {
  // products: [],
  // loading: true,
  addedItems: [],
  total: 0,
  favoris: [],
  totalFavoris: 0,
  gratuits: [],
};
/**
 * product reducer
 * @param {*} state
 * @param {*} action
 * @returns
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PRODUCTS":
      return {
        ...state,
        products: action.products,
        loading: false,
      };
    case "ADD_TO_CART":
      let addedItem = action.payload;
      // //check if the action id exists in the addedItems
      let existed_item = state.addedItems.find(
        (item) => action.payload.id === item.id
      );
      if (existed_item) {
        addedItem.quantity += 1;
        return {
          ...state,
          total: Number(state.total),
        };
      } else {
        addedItem.quantity = 1;
        //calculating the total
        let newTotal = Number(state.total) + 1;

        return {
          ...state,
          addedItems: [...state.addedItems, addedItem],
          total: newTotal,
        };
      }
    case "ADD_TO_FAVORIS":
      let addedItemFavoris = action.payload;

      //check if the action id exists in the favoris
      let existed_item_favoris = state.favoris.find(
        (item) => action.payload.id === item.id
      );

      if (existed_item_favoris) {
        addedItemFavoris.quantity += 1;
        return {
          ...state,
          totalFavoris: Number(state.totalFavoris),
        };
      } else {
        addedItemFavoris.quantity = 1;
        //calculating the totalFavoris
        let newTotal = Number(state.totalFavoris) + 1;

        return {
          ...state,
          favoris: [...state.favoris, addedItemFavoris],
          totalFavoris: newTotal,
        };
      }
    case "DELETE_CART":

      return {
        ...state,
        addedItems: state.addedItems.filter((item) => {
          return item.id != action.payload;
        }),
        total: Number(state.total) - 1,
      };

    case "DELETE_FAVORIS":
      let newFilter = state.favoris.filter((el) => el.id != action.id);
      return {
        ...state,
        favoris: newFilter,
        totalFavoris: Number(state.totalFavoris) - 1,
      };
    case "DELETE_ALL_CART":
      let emptyList = state.addedItems.splice(0, state.addedItems.length);
      return {
        ...state,
        addedItems: emptyList,
        total: 0,
      };

    case "ADD_TO_GRATUITS":
      return {
        ...state,
        gratuits: [...state.gratuits, action.payload],
        total: Number(state.total) + action.payload.length,
      };
    default:
      return state;
  }
};

export default reducer;

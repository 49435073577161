import React, { useEffect, useState } from "react";
import { getSumCredit, getSumDebit, getSumSolde, sumSoldeStatement } from "../../Utils/ObjectOperation";

const TableStatement = React.forwardRef((props, ref) => {
  const [solde, setsolde] = useState([]);

  useEffect(() => {
    setsolde(sumSoldeStatement(props.statementItems));
  }, []);
  return (
    <table className="table" ref={ref}>
      <thead >
        <tr style={{ color: "#000" }}>
          <th scope="col">Date</th>
          <th scope="col">Code</th>
          <th scope="col">Libellé</th>
          <th scope="col">Débit <span className="badge badge-pill badge-danger">{getSumDebit(props.statementItems)}</span></th>
          <th scope="col">Crédit <span className="badge badge-pill badge-success">{getSumCredit(props.statementItems)}</span></th>
          <th scope="col">Solde <span className="badge badge-pill badge-primary">{getSumSolde(solde)}</span></th>
        </tr>
      </thead>
      <tbody>
        {props.statementItems.map((el, index) => (
          <tr key={el.id}>
            <td>{el.date_mouvemenet}</td>
            <td>{el.entetcommercial_code}</td>
            <td>{el.mouvement_libelle}</td>
            <td>{el.sens_ecriture == "D" ? el.montant : "0"}</td>
            <td>{el.sens_ecriture == "C" ? el.montant : "0"}</td>
            <td>{solde[index]}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
});

export default TableStatement;
